<template>
    <div class="app-tooltip">
        <slot/>
        <span :class="tooltipClasses">{{ text }}</span>
    </div>
</template>

<script>
import {computed} from "vue";

export default {
    name: 'AppTooltip',
    props: {
        text: {
            type: String,
            default: '',
        },
        position: {
            type: String,
            default: 'bottom'
        },
    },
    setup(props) {
        // Computed
        const tooltipClasses = computed(() => ({
            'app-tooltip__text': true,
            [`app-tooltip__text--${props.position}`]: true
        }))

        return {
            tooltipClasses,
        }
    }
}
</script>

<style lang="scss" scoped>
.app-tooltip {
    position: relative;
    display: inline-block;

    :slotted(*):focus + .tooltip__text {
        visibility: visible;
        opacity: 1;
    }

    &:hover {

        .app-tooltip__text {
            visibility: visible;
            opacity: 1;
        }
    }

    &__text {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: opacity 1s;
        color: $theme-gray-300-color;
        padding: 8px;
        text-align: left;
        border-radius: 4px;
        min-width: 220px;
        font-size: .875rem;
        font-weight: 400;
        box-shadow: 0 10px 20px 0 rgba($theme-dark-color, .1);
        background: linear-gradient(0deg, $theme-darkness-color, $theme-darkness-color), linear-gradient(0deg, $theme-dark-light-color, $theme-dark-light-color);
        border: 1px solid $theme-dark-light-color;
        z-index: 1;

        &--top {
            inset-block-end: 120%;
            inset-inline-start: 50%;
            margin-inline-start: -60px;
        }

        &--bottom {
            inset-block-start: 120%;
            inset-inline-start: 50%;
            margin-inline-start: -60px;
        }

        &--left {
            inset-block-end: 0%;
            inset-inline-end: 110%;
        }

        &--right {
            inset-block-end: 0%;
            inset-inline-start: 110%;
        }
    }
}
</style>
