import axios from "axios";

const noCurrentAccountUid = () => Promise.reject(new Error('Current account not specified'));

export default {
    namespaced: true,

    state: {
    },

    getters: {
    },

    actions: {
        setupIntentClientSecret({rootGetters}) {
            const uuid = rootGetters['auth/customer_uuid']

            return uuid
                ? axios.get(`/customer/${ uuid }/setup-payment-method`)
                    .then((response) => Promise.resolve(response.data.SetupIntentClientSecret))
                    .catch(error => Promise.reject(error))
                : noCurrentAccountUid()
        },
    },

    mutations: {
    },
};
