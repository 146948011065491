import axios from "axios";

export default {
    namespaced: true,

    state: {},

    getters: {},

    actions: {
        generateToken({commit, rootGetters}, uuid) {
            const JWT = rootGetters['auth/auth_check_jwt']

            const payload = {
                "map-msisdn-to-migration": {
                    "additionalProp1": {
                        "xvne-stp-product-catalog-availability-uuid": "beb54eb7-f041-415e-8c60-ec593a3427ca"
                    }
                }
            }

            return axios.post(`migration/v1/request/${uuid}`, payload, {
                headers: {
                    'x-auth-token': JWT
                }
            })
                .then(({data}) => {
                    return data;
                })
        },
    },

    mutations: {},
};
