<template>
    <app-loader v-if="isLoading" full-block/>

    <app-stepper-content-header :step-content-data="stepContentData"/>

    <div class="app-stepper-content-body">
        <migration-step-form-social :disabled="!isAcceptTermsPolicy"
                                    @continueWithApple="signUpWithApple"
                                    @continueWithGoogle="signUpWithGoogle"
        />
        <div v-if="!isAcceptTermsPolicy" class="toperrormsg">* Please accept the Terms &amp; Conditions and Privacy Policy below to login</div>

        <migration-step-form-divider />

        <migration-create-signup-form v-if="isShowSignUp"
                                      :accept-data="formDataAccept"
                                      @startSubmitForm="startLoading"
                                      @doneSubmitForm="doneLoading"
                                      @submitForm="nextStep"
                                      @switchFormType="switchFormType"
                                      @fillLoginPreData="fillLoginPreData"
                                      @changeAcceptCheckbox="changeSingUpAcceptCheckbox"
        />

        <migration-create-login-form v-else
                                     :pre-data="loginPreData"
                                     @startSubmitForm="startLoading"
                                     @doneSubmitForm="doneLoading"
                                     @submitForm="nextStep"
                                     @switchFormType="switchFormType"
        />
    </div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {toast} from "vue3-toastify";

import AppStepperContentHeader from "@/components/app-stepper/app-stepper-content-header.vue";
import MigrationCreateSignupForm from "@/components/app-migration-steps/migration-create/form/signup-form.vue";
import MigrationCreateLoginForm from "@/components/app-migration-steps/migration-create/form/login-form.vue";
import MigrationStepFormSocial from "@/components/app-migration-steps/migration-create/form/components/social.vue";
import FormCheckbox from "@/components/app-form-components/form-checkbox.vue";
import AppLoader from "@/components/app-loader/index.vue";
import MigrationStepFormDivider from "@/components/app-migration-steps/migration-create/form/components/divider.vue";

export default {
    name: 'MigrationCreate',
    emits: ["nextStep"],
    props: {
        stepContentData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    components: {
        MigrationStepFormDivider,
        MigrationStepFormSocial,
        AppStepperContentHeader,
        MigrationCreateSignupForm,
        MigrationCreateLoginForm,
        FormCheckbox,
        AppLoader,
    },
    setup(props, {emit}) {
        // Props
        const {stepContentData} = props;

        // Store
        const store = useStore();

        // Route
        const router = useRouter();

        // Data
        const isShowSignUp = ref(true);
        const isLoading = ref(false);

        const formDataAccept = ref({
            isAcceptTerms: false,
            isAcceptPolicy: false,
            isAcceptedOffers: false,
            isAcceptedNewsletters: false,
        });

        const loginPreData = ref({});

        // Computed
        const isAcceptTermsPolicy = computed(() => {
            return formDataAccept.value.isAcceptTerms && formDataAccept.value.isAcceptPolicy
        })

        const customerToken = computed(() => {
            return store.getters['firebase_auth/customer_token']
        })

        // Methods
        function nextStep() {
            router.push({name: 'Payment'});
            emit('nextStep');
        }

        function startLoading() {
            isLoading.value = true;
        }

        function doneLoading() {
            isLoading.value = false;
        }

        function changeSingUpAcceptCheckbox(value) {
            formDataAccept.value = value
        }

        function signUpWithApple() {
            isLoading.value = true;

            const updateUserPayload = {
                'is-accepted-terms': true,
                NotifySpecialOffers: formDataAccept.value.isAcceptedOffers ? 2 : 0,
                NotifyNewsletters: formDataAccept.value.isAcceptedNewsletters ? 2 : 0
            }

            store.dispatch('firebase_auth/FirebaseAppleAuth')
                .then(() => store.dispatch('firebase_accounts/updateUser', updateUserPayload))
                .then(() => store.dispatch('auth/attemptUser', customerToken.value))
                .then(() => nextStep())
                .catch((error) => {
                    if (error.message) {
                        toast.error(error.message)
                    }
                })
                .finally(() => isLoading.value = false)
        }

        function signUpWithGoogle() {
            isLoading.value = true;

            const updateUserPayload = {
                'is-accepted-terms': true,
                NotifySpecialOffers: formDataAccept.value.isAcceptedOffers ? 2 : 0,
                NotifyNewsletters: formDataAccept.value.isAcceptedNewsletters ? 2 : 0
            }

            store.dispatch('firebase_auth/FirebaseGoogleAuth')
                .then(() => store.dispatch('firebase_accounts/updateUser', updateUserPayload))
                .then(() => store.dispatch('auth/attemptUser', customerToken.value))
                .then(() => nextStep())
                .catch((error) => {
                    if (error.message) {
                        toast.error(error.message)
                    }
                })
                .finally(() => isLoading.value = false)
        }

        function fillLoginPreData(data) {
            loginPreData.value = {...data}
        }

        function switchFormType(isSignUp) {
            stepContentData.title = "Create your new Rocket Mobile account";
            stepContentData.subtitle = "Please choose how you want to login to your new Rocket Mobile Account";

            if (!isSignUp) {
                stepContentData.title = "Log in to your Rocket Mobile account";
                stepContentData.subtitle = "Please choose how you want to login to your Rocket Mobile Account";
            }

            isShowSignUp.value = isSignUp
        }

        return {
            stepContentData,
            isShowSignUp,
            isLoading,
            formDataAccept,
            isAcceptTermsPolicy,
            loginPreData,
            nextStep,
            switchFormType,
            changeSingUpAcceptCheckbox,
            signUpWithApple,
            signUpWithGoogle,
            fillLoginPreData,
            startLoading,
            doneLoading,
        }
    }
}
</script>

<style lang="scss" scoped>
.toperrormsg {
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: 5px;
    font-size: 0.75rem;
    color: #FE4C6C;
}
:deep {
    .migration-step-form-body {
        margin-bottom: 15px;
    }

    .app-loader__mask {
        background: rgba($theme-darkness-light-color, .9);
        border-radius: 8px;
    }
}

.row-form-accept {
    margin-top: 40px;

    .form-input {
        margin-bottom: 20px;

        @media (min-width: $sm) {
            margin-bottom: 30px;
        }
    }

    .form-checkbox {
        margin-bottom: 15px;
    }
}
</style>
