import firebase from 'firebase/app';

const displayName = (creds) => {
    if (!creds) return '';
    if (!creds.displayName || !creds.displayName.trim()) return creds.email || '';

    const filteredFirstNameAndLastName = creds.displayName.split(' ').filter((x) => x);

    if (filteredFirstNameAndLastName.length === 1) return filteredFirstNameAndLastName[0];

    const [first_name, last_name] = filteredFirstNameAndLastName;
    if (first_name.length + last_name.length > 33) return first_name;

    return creds.displayName;
};

export default {
    namespaced: true,

    state: {
        auth_user: null,
        auth_token: null,
        current_auth_user_uid: null,
        firebase_identity: localStorage.getItem('firebase_identity'),
        customer_token: localStorage.getItem('customer_token') || new URLSearchParams(window.location.search).get("token"),
    },

    getters: {
        auth_user: (state) => state.auth_user,
        auth_token: (state) => state.auth_token,
        auth_user_uid: (state) => (state.auth_user && state.auth_user.uid) || '',
        firebase_identity: (state) => state.firebase_identity,
        customer_token: (state) => state.customer_token,
    },

    actions: {
        FirebaseSignUp({getters, dispatch}, user_data) {
            return firebase
                .auth()
                .createUserWithEmailAndPassword(user_data.email, user_data.passnew)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    const uid = user.uid;

                    return user.updateProfile(
                        {displayName: `${user_data.firstname.trim()}  ${user_data.lastname.trim()}`}
                    ).then(() => {
                        return dispatch('firebase_accounts/GetAccountByUid', uid, {root: true}).then((account)=> {
                            if (account) {
                                return dispatch('FirebaseOnAuth');
                            } else {
                                return dispatch('firebase_accounts/CreateAccount', { user_data: {}, uid }, {root: true}).then(()=> {
                                    return dispatch('FirebaseOnAuth');
                                });
                            }
                        });
                    })
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },

        FirebaseSignInEmailPassword({getters, dispatch}, {email, login, pass}) {
            const sign_in_email = email || login

            return firebase
                .auth()
                .signInWithEmailAndPassword(sign_in_email, pass)
                .then(() => {
                    // Signed in
                    return dispatch('FirebaseOnAuth');
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },

        FirebaseGoogleAuth({getters, dispatch}) {
            const provider = new firebase.auth.GoogleAuthProvider();

            provider.setCustomParameters({
                prompt: 'select_account',
            });

            firebase.auth().languageCode = 'en';

            return firebase
                .auth()
                .signInWithPopup(provider)
                .then((result) => {

                    // The signed-in user info.
                    const user = result.user;
                    const uid = user.uid;

                    return dispatch('firebase_accounts/GetAccountByUid', uid, {root: true}).then((account)=> {
                        if (account) {
                            return dispatch('FirebaseOnAuth');
                        } else {
                            return dispatch('firebase_accounts/CreateAccount', { user_data: {}, uid }, {root: true}).then(()=> {
                                return dispatch('FirebaseOnAuth');
                            });
                        }
                    });
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },

        FirebaseAppleAuth({getters, dispatch, commit}) {
            const provider = new firebase.auth.OAuthProvider('apple.com');

            provider.setCustomParameters({
                prompt: 'select_account',
            });

            firebase.auth().languageCode = 'en';

            return firebase
                .auth()
                .signInWithPopup(provider)
                .then((result) => {
                    // The signed-in user info.
                    const user = result.user;
                    const uid = user.uid;

                    return dispatch('firebase_accounts/GetAccountByUid', uid, {root: true}).then((account)=> {
                        if (account) {
                            return dispatch('FirebaseOnAuth');
                        } else {
                            return dispatch('firebase_accounts/CreateAccount', { user_data: {}, uid }, {root: true}).then(()=> {
                                return dispatch('FirebaseOnAuth');
                            });
                        }
                    });
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },

        FirebaseOnAuth({state, dispatch, commit} = {}) {
            const current_auth_user_uid = state.current_auth_user_uid

            const auth_state_changed = new Promise((resolve, reject) => {
                const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
                    if (!user) {
                        unsubscribe();

                        dispatch('FirebaseLogout')
                    } else if (current_auth_user_uid && user.uid !== current_auth_user_uid) {
                        unsubscribe();

                        reject();
                    }

                    resolve(user);
                });
            });

            return auth_state_changed
                .then((user) => {
                    if (!user) return Promise.resolve();

                    commit('SAVE_CURRENT_AUTH_USER_UID', user.uid);

                    commit('SAVE_FIREBASE_IDENTITY', user.Aa);

                    user.getIdToken().then((token) => {
                        commit('SAVE_AUTH_TOKEN', token);
                    });

                    if (user.photoURL) {
                        commit('SAVE_AUTH_USER', user);
                    } else {
                        commit('SAVE_AUTH_USER', {
                            ...user,
                            photoURL: user.providerData?.[0]?.photoURL,
                        });
                    }
                })
                .catch((error) => {
                    dispatch('FirebaseLogout');

                    return Promise.reject(error);
                });
        },

        FirebaseLogout({getters, dispatch, commit}) {
            return firebase
                .auth()
                .signOut()
                .then(() => {
                    commit('FIREBASE_LOGOUT');
                });
        },
    },

    mutations: {
        SAVE_AUTH_USER(state, user) {
            state.auth_user = {...user};
        },

        SAVE_AUTH_TOKEN(state, token) {
            if (state.auth_token != token) {
                state.auth_token = token;
            }
        },

        SAVE_CURRENT_AUTH_USER_UID(state, user_uid) {
            state.current_auth_user_uid = user_uid
        },

        SAVE_FIREBASE_IDENTITY(state, firebase_identity) {
            localStorage.setItem('firebase_identity', firebase_identity);
            state.firebase_identity = firebase_identity;
        },

        SAVE_CURRENT_TOKEN(state, customer_token) {
            localStorage.setItem('customer_token', customer_token);
            state.customer_token = customer_token;
        },

        FIREBASE_LOGOUT(state) {
            localStorage.removeItem('firebase_identity');

            state.auth_user = null;
            state.auth_token = null;
            state.firebase_identity = null;
        },
    },
};
