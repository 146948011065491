<template>
    <div class="app-stepper">
        <div class="app-stepper-progress">
            <app-stepper-progress-item v-for="step in stepsProgress"
                                       :key="step.id"
                                       :step="step"
                                       :active-step="activeStep"
            />
        </div>
        <div class="app-stepper-content">
            <div class="app-stepper-content__number">{{ activeStep.number }}</div>
            <slot :activeStep="activeStep"></slot>
        </div>
    </div>
</template>

<script>
import AppStepperProgressItem from "@/components/app-stepper/app-stepper-progress-item.vue";

export default {
    name: 'AppStepper',
    components: {
        AppStepperProgressItem,
    },
    props: {
        steps: {
            type: Array,
            required: true
        },
        activeStep: {
            type: Object,
            required: true
        },
    },
    created() {
        this.addedIndexToSteps()
    },
    computed: {
        stepsProgress() {
            let currentIndex = this.activeStep.index
            let lastIndex = this.activeStep.index + 1

            if (lastIndex >= this.steps.length) {
                currentIndex = this.activeStep.index - 1
                lastIndex = this.activeStep.index
            }

            return this.steps
        }
    },
    methods: {
        addedIndexToSteps() {
            this.steps.forEach((step, index) => step.index = index)
            this.activeStep.index = this.steps.findIndex(step => step.id === this.activeStep.id)
        },
        prevStep() {
            this.$emit('prevStep')
        }
    }
}
</script>

<style lang="scss" scoped>
.app-stepper {

    &-progress {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        max-width: 290px;
        margin: 0 auto 30px;

        @media (min-width: $sm) {
            max-width: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 23px;
            left: 50%;
            transform: translateX(-50%);
            width: 230px;
            height: 8px;
            background: $theme-dark-color;
            box-shadow: 0 0 2px 0 rgba($theme-black-color, .4) inset;

            @media (min-width: $sm) {
                display: none;
            }
        }

        @media (min-width: $lg) {
            padding: 0 40px;
        }
    }

    &-content {
        position: relative;
        padding: 16px;
        background: $theme-darkness-light-color;
        border-radius: 8px;
        box-shadow: 0 10px 20px 0 rgba($theme-black-color, .1);
        min-height: 240px;
        z-index: 10;

        @media (min-width: $sm) {
            padding: 24px 32px;
        }

        @media (min-width: $lg) {
            padding: 48px 65px;
        }

        :deep {
            .app-stepper-content-header {
                margin-bottom: 20px;

                @media (min-width: $sm) {
                    margin-bottom: 40px;
                }
            }

            .app-stepper-content__title {
                font-size: 1.375rem;
                font-weight: 700;
                color: $theme-primary-color;
                margin-bottom: 5px;

                @media (min-width: $sm) {
                    font-size: 1.6rem;
                    margin-bottom: 0;
                }

                @media (min-width: $lg) {
                    font-size: 2rem;
                }
            }

            .app-stepper-content__subtitle {
                font-size: .75rem;
                font-weight: 500;
                color: $white;

                @media (min-width: $sm) {
                    font-size: 1rem;
                }
            }
        }

        &__number {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 20rem;
            font-weight: 900;
            color: $theme-darkness-color;
            line-height: .8;
            z-index: -2;
            user-select: none;
        }
    }
}
</style>
