<template>
    <form-input ref="input-component"
                :input_id="input_id"
                :type="type"
                :icon="icon"
                :inputmode="inputmode"
                :disabled="disabled"
                :maxlength="maxlength"
                :placeholder="placeholder"
                :readonly="readonly"
                :is-not-password="isNotPassword">
        <div class="app-input-stripe__input" :id="calculatedId"/>
    </form-input>
</template>

<script>
import FormInput from "@/components/app-form-components/form-input.vue";

export default {
    name: 'FormInputStripe',
    components: {
        FormInput,
    },
    props: {
        input_id: {
            type: String
        },
        modelValue: {
            required: false
        },
        type: {
            type: String,
            default: 'text'
        },
        inputmode: {
            type: String,
            default: 'text'
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        },
        maxlength: {
            default: 100
        },
        placeholder: {
            default: ''
        },
        readonly: {
            type: Boolean,
            default: false
        },
        isNotPassword: {
            type: Boolean,
            default: false
        },
        stripeElementType: {
            type: String,
            required: true
        },
        masking: {
            type: String,
            default: ''
        },
        stripeElements: {
            required: true
        }
    },
    data() {
        return {
            stripeElement: null,
            error: null,
        }
    },
    computed: {
        calculatedId() {
            return this.id || `stripe-${this.stripeElementType}`
        }
    },
    methods: {
        initStripeElement() {
            if (!this.stripeElement) {
                const card_element = this.stripeElements._elements.find(element => element._componentName == 'card')
                if (card_element) {
                    card_element.destroy()
                }

                this.stripeElement = this.stripeElements._elements.find(element => element._componentName == this.stripeElementType)
                    || this.stripeElements.create(this.stripeElementType);

                this.stripeElement.mount(`#${this.calculatedId}`)


                this.stripeElement.on('ready', () => this.$emit('mounted', this.stripeElement))
                this.stripeElement.on('change', this.onElementChange)

                this.$nextTick(() => {
                    this.getInputComponentStyles()
                })
            }
        },
        onElementChange($event) {
            const {complete, error} = $event

            this.error = error ? error.message : null

            if (complete) {
                this.$emit('complete', {stripeElement: this.stripeElement})
            }

            this.$nextTick(() => {
                this.getInputComponentStyles()
            })
        },
        getInputComponentStyles() {
            if (this.stripeElement) {
                const input_component_styles = getComputedStyle(this.$el)

                const fontSize = '14px'
                const color = '#fff'
                const fontFamily = input_component_styles.fontFamily.trim()

                const styles = {
                    fontSize,
                    fontFamily,
                    color,
                    fontSmoothing: 'antialiased',
                    '::placeholder': {
                        color: '#fff',
                    },
                }

                this.stripeElement.update({
                    style: {
                        base: styles,
                        complete: styles,
                        empty: styles,
                        invalid: styles,
                    },
                })
            }
        },
    },
    mounted() {
        this.initStripeElement();
    }
}
</script>

<style lang="scss" scoped>
.form-input {
    position: relative;

    &.is-icon {

        input {
            padding-left: 40px;
        }
    }

    &.is-error {

        input {

            &::placeholder {
                color: $danger;
            }
        }

        .form-input__icon {
            fill: $danger;
        }

        .form-input__show-pass-icon {
            fill: $danger;
        }
    }

    input {
        width: 100%;
        height: auto;
        padding: 16px;
        border-radius: 4px;
        border: none;
        outline: none;
        background: $theme-dark-color;
        font-size: .75rem;
        color: $white;
        font-weight: 400;

        @media (min-width: $sm) {
            font-size: .875rem;
        }

        &::placeholder {
            color: $theme-gray-200-color;
        }

        &:-webkit-autofill{
            -webkit-text-fill-color: $theme-dark-color !important;
        }
    }

    &__icon {
        position: absolute;
        top: 24px;
        left: 8px;
        transform: translateY(-50%);
        z-index: 5;
        fill: white;
    }

    &__show-pass-icon {
        position: absolute;
        top: 27px;
        right: 8px;
        transform: translateY(-50%);
        z-index: 5;
        fill: white;
        cursor: pointer;
    }

    &__error-text {
        margin-top: 5px;
        font-size: .75rem;
        color: $danger;
    }
}
</style>
