<template>
    <div class="row">
        <div class="col-sm-10 col-lg-8">
            <div class="migration-payment-cards">
                <migration-payment-card v-for="card in cards"
                                        :key="card.UUID"
                                        :card="card"
                                        :value="card.UUID"
                                        v-model="selectedCard"/>
            </div>

            <app-simple-icon-button icon="form-input-card-add-new" @click="handleAddNewCard">
                Add new card
            </app-simple-icon-button>
        </div>
    </div>

    <app-animation-button @click="successAddPaymentMethod"/>
</template>

<script>
import {ref, toRefs} from "vue";

import MigrationPaymentCard from "@/components/app-migration-steps/migration-payment/cards/card.vue";
import AppSimpleIconButton from "@/components/app-simple-icon-button/index.vue";
import AppAnimationButton from "@/components/app-animation-button/index.vue";

export default {
    name: 'MigrationPaymentCards',
    emits: ['successAddPaymentMethod', 'addNewCard'],
    props: {
        cards: {
            type: Array,
            required: true
        }
    },
    components: {
        AppAnimationButton,
        AppSimpleIconButton,
        MigrationPaymentCard
    },
    setup(props, {emit}) {
        // Props
        const {cards} = toRefs(props);

        // Data
        const selectedCard = ref(cards.value[0].UUID);

        // Methods
        function successAddPaymentMethod() {
            emit('successAddPaymentMethod')
        }

        function handleAddNewCard() {
            emit('addNewCard', true)
        }

        return {
            cards,
            selectedCard,
            successAddPaymentMethod,
            handleAddNewCard,
        }
    }
}
</script>

<style lang="scss" scoped>
.migration-payment-cards {
    padding: 0 8px;
    border-radius: 6px;
    margin-bottom: 20px;
    background: $theme-dark-color;
    box-shadow: 0 10px 20px 0 rgba($theme-black-color, .1);

    @media (min-width: $sm) {
        padding: 0 16px;
    }

    @media (min-width: $sm) {
        margin-bottom: 30px;
    }

    :deep {
        .migration-payment-card {
            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}

.btn-simple-with-icon {
    margin-bottom: 20px;

    @media (min-width: $sm) {
        margin-bottom: 30px;
    }
}
</style>
