<template>
    <button class="animation-button"
            :style="{width: buttonSize.width + 'px', height: buttonSize.height + 'px'}"
            :disabled="disabled"
            @mouseover="handleMouseOver">
        <canvas ref="animationButtonCanvas"
                :width="buttonSize.width * 2"
                :height="buttonSize.height * 2"
        />
    </button>
</template>

<script>
import {onMounted, ref} from "vue";
import {Layout, Rive} from "@rive-app/canvas";

export default {
    name: 'AppAnimationButton',
    props: {
        buttonText: {
            type: String,
            default: 'Continue'
        },
        buttonSize: {
            type: Object,
            default() {
                return {
                    width: 240,
                    height: 48,
                }
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        // Props
        const {buttonSize, buttonText, disabled} = props;

        // Data
        let buttonRive = null;

        // Refs
        const animationButtonCanvas = ref(null);

        // Methods
        function handleMouseOver() {
            buttonRive.play('Active')
        }

        onMounted(() => {
            buttonRive = new Rive({
                canvas: animationButtonCanvas.value,
                src: require('@theme/assets/animations/button_continue.riv'),
                layout: new Layout({
                    alignment: 'Center',
                }),
                stateMachines: 'State_Machine_1',
                autoplay: true,
                onLoad: () => {
                    buttonRive.setTextRunValue("Run1", buttonText);
                }
            });
        })

        return {
            buttonSize,
            animationButtonCanvas,
            handleMouseOver,
        }
    }
}
</script>

<style lang="scss" scoped>
.animation-button {
    display: inline-flex;
    padding: 0;
    outline: none;
    border: none;

    &[disabled] {
        pointer-events: none;
        user-select: none;
        opacity: .65;
    }

    canvas {
        transform: scale(0.5);
        transform-origin: 0 0;
    }
}
</style>
