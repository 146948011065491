<template>
    <div class="migration-done-download-app">
        <h5 class="migration-done-download-app__title">
            Now you can start enjoy your services on our user portal:
        </h5>

        <app-animation-button button-text="Start now" @click="redirectToPortal" />

        <h5 class="migration-done-download-app__subtitle">
            Download the Rocket Mobile app:
        </h5>
        <div class="migration-done-download-app-buttons">
            <a :href="appStoreDownloadUrl" class="migration-done-download-app__btn">
                <img src="@theme/assets/app-store-download-button.svg" alt="app store download button">
            </a>
            <a :href="appGooglePlayDownloadUrl" class="migration-done-download-app__btn">
                <img src="@theme/assets/google-play-download-button.svg" alt="google play download button">
            </a>
        </div>
    </div>
</template>

<script>
import {ref} from "vue";

import AppAnimationButton from "@/components/app-animation-button/index.vue";

export default {
    name: 'MigrationDoneDownloadApp',
    components: {
        AppAnimationButton
    },
    setup() {
        // Data
        const redirectServiceURL = process.env.VUE_APP_MAIN_USER_PORTAL_URL
        const appStoreDownloadUrl = ref(process.env.VUE_APP_STORE_DOWNLOAD_URL);
        const appGooglePlayDownloadUrl = ref(process.env.VUE_APP_GOOGLE_PLAY_DOWNLOAD_URL);

        // Methods
        function redirectToPortal() {
            window.location.href = redirectServiceURL;
        }

        return {
            appStoreDownloadUrl,
            appGooglePlayDownloadUrl,
            redirectToPortal,
        }
    }
}
</script>

<style lang="scss" scoped>
.migration-done-download-app {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 40px 0 32px;

    :deep {
        .animation-button {
            margin-bottom: 60px;
        }
    }

    &__title {
        font-size: .875rem;
        font-weight: 400;
        color: $white;
        margin-bottom: 30px;

        @media (min-width: $sm) {
            font-size: 1rem;
        }
    }

    &__subtitle {
        font-size: .875rem;
        font-weight: 400;
        color: $white;
        margin-bottom: 30px;

        @media (min-width: $sm) {
            font-size: 1rem;
        }
    }

    &-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media (min-width: $sm) {
            flex-direction: row;
        }
    }

    &__btn {
        margin-bottom: 30px;

        @media (min-width: $sm) {
            margin-right: 30px;
            margin-bottom: 0;
        }

        &:last-of-type {
            margin-right: 0;
            margin-bottom: 0;
        }

        img {
            width: 162px;
        }
    }
}
</style>
