import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import styles from './assets/scss/index.scss';

import Vue3Toastify from 'vue3-toastify';

import '@/utils/axios';
import axios from "axios";
import axiosMiddleware from '@/middlewares/axios-middleware';

document.title = 'Migration Journey';

createApp(App)
    .use(store)
    .use(router)
    .use(styles)
    .use(axiosMiddleware, axios, store, router)
    .use(Vue3Toastify, {theme: 'dark'})
    .mount('#app')
