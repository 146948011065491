<template>
    <form @submit.prevent="generateToken">
        <div class="row">
            <div class="col-12">
                <h2>
                    Token generate form
                </h2>
            </div>
            <div class="col-12">
                <form-input v-model="formData.UUID"
                            type="text"
                            placeholder="Customer UUID"
                            icon="form-input-user"/>
            </div>
            <div v-if="token" class="col-12 mt-4">
                <app-hint>
                    <p>
                        {{ token }}
                    </p>
                </app-hint>
            </div>
            <div class="col-12 mt-5">
                <app-animation-button button-text="Generate" />
            </div>
        </div>
    </form>
</template>

<script>
import {useStore} from "vuex";
import {ref} from "vue";

import {toast} from "vue3-toastify";

import FormInput from "@/components/app-form-components/form-input.vue";
import AppAnimationButton from "@/components/app-animation-button/index.vue";
import AppHint from "@/components/app-hint/index.vue";

export default {
    name: 'AppGenerateTokenForm',
    components: {
        FormInput,
        AppAnimationButton,
        AppHint
    },
    setup() {
        // Data
        const formData = ref({
            UUID: ''
        })

        const token = ref('')

        // Store
        const store = useStore();

        // Methods
        function generateToken() {
            store.dispatch('token/generateToken', formData.value.UUID)
                .then((data) => {
                    token.value = data.url;
                })
                .catch((error) => {
                    if (error.message) {
                        toast.error(error.message)
                    }
                })
        }

        return {
            formData,
            token,
            generateToken,
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
