<template>
    <div class="profile-details">
        <div class="row">
            <div class="col-12">
                <div class="profile-details-table-email">
                    <span class="profile-details-table__value">{{ profileDetailsData.email }}</span>
                </div>
                <div class="profile-details-table">
                    <div class="profile-details-table-container">
                        <div class="profile-details-table-col">
                            <div class="profile-details-table-row">
                                <span class="profile-details-table__name">First name</span>
                                <span class="profile-details-table__value">{{ profileDetailsData.first_name }}</span>
                            </div>
                            <div class="profile-details-table-row">
                                <span class="profile-details-table__name">Last name</span>
                                <span class="profile-details-table__value">{{ profileDetailsData.last_name }}</span>
                            </div>
                            <div class="profile-details-table-row" v-if="profileDetailsData.phone">
                                <span class="profile-details-table__name">Contact Number</span>
                                <span class="profile-details-table__value">{{ profileDetailsData.phone }}</span>
                            </div>
                        </div>
                        <div class="profile-details-table-col">
                            <div class="profile-details-table-row" v-if="profileDetailsData.account_number">
                                <span class="profile-details-table__name">Account Number</span>
                                <span class="profile-details-table__value">{{
                                        profileDetailsData.account_number || ''
                                    }}</span>
                            </div>
                            <div class="profile-details-table-row">
                                <span class="profile-details-table__name">Current Account Balance</span>
                                <span class="profile-details-table__value" v-if="billingDetailsData.account_balance">
                                    {{ (billingDetailsData.account_balance) < 0 ? '- $' : '$' }} {{ (Math.abs(billingDetailsData.account_balance) / 100).toFixed(2) || '0.00' }}
                                </span>
                                <span class="profile-details-table__value" v-else>
                                    $ 0.00
                                </span>
                            </div>
                            <div class="profile-details-table-row">
                                <span class="profile-details-table__name">Renews on</span>
                                <span class="profile-details-table__value">
                                {{ billingDetailsData.account_renews_on || 'TBD' }}
                                <app-tooltip v-if="billingDetailsData.account_renews_on"
                                             text="Your new Rocket Mobile services renew on this date">
                                    <svg width="24" height="24">
                                        <use xlink:href="#exclam-circle-icon"></use>
                                    </svg>
                                </app-tooltip>
                                <app-tooltip v-if="!billingDetailsData.account_renews_on"
                                             text="Your new Rocket Mobile services renew on your service anniversary date">
                                    <svg width="24" height="24">
                                        <use xlink:href="#exclam-circle-icon"></use>
                                    </svg>
                                </app-tooltip>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppTooltip from "@/components/app-tooltip/index.vue";

export default {
    name: 'ProfileDetails',
    components: {AppTooltip},
    props: {
        profileDetailsData: {
            type: Object,
            required: true
        },
        billingDetailsData: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.profile-details {
    position: relative;
    margin-bottom: 30px;
    margin-top: -1em;

    &__title {
        font-size: .875rem;
        font-weight: 700;
        color: $white;
        margin-bottom: 12px;

        @media (min-width: $sm) {
            font-size: 1rem;
        }
    }

    &-table {
        position: relative;
        border: 2px solid $theme-dark-color;
        border-radius: 6px;
        background: linear-gradient(0deg, $theme-darkness-light-color, $theme-darkness-light-color), linear-gradient(0deg, $theme-dark-color, $theme-dark-color);

        &-container {
            display: flex;
            flex-direction: column;

            @media (min-width: $md) {
                flex-direction: row;
            }
        }

        &-email {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            font-size: 1em;

            @media (min-width: $md) {
                padding: 16px;
            }
        }

        :deep {
            .app-tooltip {
                line-height: 1;

                svg {
                    margin-left: 8px;
                    fill: $theme-secondary-color;
                }

                &__text {
                    &--bottom {
                        @media (max-width: $md) {
                            inset-inline-start: -400%;
                        }
                    }
                }
            }
        }

        &-col {
            width: 100%;
            padding: 8px;

            @media (min-width: $md) {
                padding: 16px;
            }

            @media (min-width: $md) {
                width: 50%;
                max-width: 50%;
                flex: 1 0 50%;
            }

            .profile-details-table-row {

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        &-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
        }

        &__name {
            font-size: .75rem;
            font-weight: 400;
            color: $white;

            @media (min-width: $sm) {
                font-size: .875rem;
            }

            @media (min-width: $lg) {
                font-size: 1rem;
            }
        }

        &__value {
            display: flex;
            align-items: center;
            font-size: .875rem;
            font-weight: 600;
            color: $white;
            text-align: right;

            @media (min-width: $sm) {
                font-size: 1rem;
            }

            @media (min-width: $lg) {
                font-size: 1.125rem;
            }

            :deep {
                img {
                    cursor: pointer;
                    margin-top: 5px;
                    margin-left: 8px;
                }
            }
        }

        //&::before {
        //    content: '';
        //    position: absolute;
        //    display: none;
        //    top: 0;
        //    left: 50%;
        //    transform: translateX(-50%);
        //    width: 1px;
        //    height: 100%;
        //    background: $theme-dark-color;
        //
        //    @media (min-width: $md) {
        //        display: block;
        //    }
        //}
    }
}
</style>
