class ValidatorClass {
    constructor(rules) {
        this.rules = rules
        this.error = null
    }

    mixin(rules) {
        for (const key in rules) {
            this.rules[key] = key in this.rules
                ? {
                    ...this.rules[key],
                    ...rules[key],
                }
                : rules[key]
        }
    }

    passwordContainsCount(password, allowedChars) {
        var count = 0
        for (var i = 0; i < password.length; i++) {
            var char = password.charAt(i);
            if (allowedChars.indexOf(char) >= 0) { count++; }
        }
        return count;
    }

    passwordErrorString(password) {
		let is_valid = true;

		if (password.length < 10) {
			 //  return 'Password must be at least 8 characters long'
			 is_valid = false;
        } else {
            const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            if (this.passwordContainsCount(password, uppercase) < 1) {
                // return 'Password must contain an upper case character'
				is_valid = false;
            }
            const lowercase = "abcdefghijklmnopqrstuvwxyz";
            if (this.passwordContainsCount(password, lowercase) < 1) {
                // return 'Password must contain a lower case character'
				is_valid = false;
            }
            const digitsOrSplChars = "0123456789!@#$%&*()";
            if (this.passwordContainsCount(password, digitsOrSplChars) < 1) {
				is_valid = false;
            }

            /* const digits = "0123456789";
            if (this.passwordContainsCount(password, digits) < 1) {
                // return 'Password must contain a number'
				is_valid = false;
            } */
            /* const splChars ="!@#$%&*()";
            if (this.passwordContainsCount(password, splChars) < 1) {
                return 'Password must contain a special character'
            } */
        }

		if (!is_valid) return 'Password must contain 10 or more characters, UPPER & lower case letters and at least one number or symbol';

        return null
    }

    isRuleExists(key) {
        return key in this.rules
    }

    isRequired(key) {
        return key in this.rules && this.rules[key].required
    }

    isInvalid(key, value, custom_rules = {}) {
        const rule_key = custom_rules.rule || key
        this.error = null

        if (this.isRuleExists(rule_key)) {
            const rule = {
                ...this.rules[rule_key],
                ...(custom_rules instanceof Object ? custom_rules : {custom_message: custom_rules})
            }

            const value_exists = (Array.isArray(value) ? value.length : value)

            if (rule.required && !value_exists) {
                this.error = '* Required field'
            } else if (rule.customPasswordCheck) {
                this.error = this.passwordErrorString(value)
                return this.error
            } else if (value_exists) {
                if (rule.maxlength && value.length > rule.maxlength) {
                    this.error = `${ rule.name } cant be longer than ${ rule.maxlength } characters`
                } else {
                    if (rule.replaces) {
                        for (let n = 0, len = rule.replaces.length; n < len; n++) {
                            const rule_replace = rule.replaces[n]

                            if (!rule_replace.format || rule_replace.format.test(value)) {
                                value = value.replace(rule_replace.pattern, rule_replace.replace)
                            } else {
                                this.error = rule.custom_message || `${ rule.name } format is invalid`
                                break
                            }
                        }
                    }

                    if (!this.error) {
                        if (rule.format && !rule.format.test(value)) {
                            this.error = rule.custom_message || `${ rule.name } format is invalid`
                        }
                    }
                }
            }

        } else {
            throw new Error(`rule "${ rule_key }" not found in validator`)
        }

        return this.error
    }

    getError() {
        return this.error
    }

    mobile(phone) {
        let mobile = phone.replace(/\D/g, '')

        if (/^\s*\+/g.test(phone)) {
            mobile = '+' + mobile
        }

        return mobile
    }
}

export default ValidatorClass
