import { createRouter, createWebHistory } from 'vue-router';

import permissions from "@/router/permissions";

import MigrationCreate from "@/components/app-migration-steps/migration-create/index.vue";
import MigrationPayment from "@/components/app-migration-steps/migration-payment/index.vue";
import MigrationConfirmation from "@/components/app-migration-steps/migration-confirmation/index.vue";
import MigrationDone from "@/components/app-migration-steps/migration-done/index.vue";
import TokenGeneratePage from "@/views/token-generate/index.vue";
import Home from "@/views/home/index.vue";

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        name: 'Create',
        component: MigrationCreate,
        beforeEnter: permissions(['is-unauthorized']),
      },
      {
        path: '/payment',
        name: 'Payment',
        component: MigrationPayment,
        beforeEnter: permissions(['is-auth']),
      },
      {
        path: '/confirm',
        name: 'Confirm',
        component: MigrationConfirmation,
        beforeEnter: permissions(['is-auth']),
      },
      {
        path: '/done',
        name: 'Done',
        component: MigrationDone,
        beforeEnter: permissions(['is-auth']),
      },
    ],
  },
  {
    path: '/generate',
    name: 'TokenGenerate',
    component: TokenGeneratePage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
