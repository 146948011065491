<template>
    <app-stepper-content-header :step-content-data="stepContentData"/>

    <div class="app-stepper-content-body">
        <migration-done-loader v-if="isShowLoader"/>
        <div v-else>
            <migration-done-download-app v-if="isSuccessConfirm"/>
            <migration-fail-confirm v-else @tryAgain="tryConfirmAgain"/>
        </div>
    </div>
</template>

<script>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";

import MigrationDoneLoader from "@/components/app-migration-steps/migration-done/components/loader.vue";
import AppStepperContentHeader from "@/components/app-stepper/app-stepper-content-header.vue";
import MigrationDoneDownloadApp from "@/components/app-migration-steps/migration-done/components/download-app.vue";
import MigrationFailConfirm from "@/components/app-migration-steps/migration-done/components/fail-confirm.vue";

export default {
    name: 'MigrationDone',
    emits: ["nextStep"],
    props: {
        stepContentData: {
            type: Object,
            default() {
                return {
                    title: "Title",
                    subtitle: "Subtitle"
                }
            }
        }
    },
    components: {
        MigrationDoneLoader,
        AppStepperContentHeader,
        MigrationDoneDownloadApp,
        MigrationFailConfirm,
    },
    setup(props) {
        // Props
        const {stepContentData} = props;

        // Data
        const isShowLoader = ref(true);
        const isSuccessConfirm = ref(false);

        // Store
        const store = useStore();

        // Methods
        function changeDataAfterLoad(isSuccess) {
            isShowLoader.value = false;
            isSuccessConfirm.value = isSuccess

            if (isSuccess) {
                stepContentData.title = 'All Done!';
                stepContentData.subtitle = 'Thanks for your patience!';
                return;
            }

            stepContentData.title = 'Oops!';
            stepContentData.subtitle = 'Something went wrong, try again!';
        }

        function confirmMigration() {
            isShowLoader.value = true;

            store.dispatch('confirm/confirmMigration')
                .then(() => changeDataAfterLoad(true))
                .catch(() => changeDataAfterLoad(false))
        }

        function tryConfirmAgain() {
            confirmMigration()
        }

        // Mounted
        onMounted(() => {
            confirmMigration()
        })

        return {
            stepContentData,
            isShowLoader,
            isSuccessConfirm,
            tryConfirmAgain,
        }
    }
}
</script>

<style lang="scss" scoped>
:root {
    .app-stepper-content-header {
        text-align: center;
    }
}
</style>
