<template>
    <div :class="progressItemClass" :style="{width: stepLineSize.width + 'px', height: stepCountSize.height + 'px'}">
        <canvas class="app-stepper-progress-item__count"
                ref="stepCountCanvas"
                :width="stepCountSize.width * 2"
                :height="stepCountSize.height * 2"
        />
        <canvas class="app-stepper-progress-item__fill-line"
                ref="stepLineCanvas"
                :width="stepLineSize.width * 2"
                :height="stepLineSize.height * 2"
        />
        <div class="app-stepper-progress-item__title">{{ step.title }}</div>
    </div>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, ref, toRefs, watch} from "vue";

import {Rive, Layout} from '@rive-app/canvas'

export default {
    name: 'AppStepperProgressItem',
    props: {
        step: {
            type: Object,
            required: true
        },
        activeStep: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        // Props
        const {step, activeStep} = toRefs(props);

        // Data
        let stepCountRive = null;
        let stepCountInputs = null;
        let stepCountStartTrigger = null;
        let doneCountStartTrigger = null;

        let stepLineRive = null;
        let stepLineInputs = null;
        let stepLineProcessTrigger = null;
        let doneLineStartTrigger = null;

        const stepCountSize = ref({
            width: 90,
            height: 90,
        })
        const stepLineSize = ref({
            width: 230,
            height: 16,
        })

        // Refs
        const stepCountCanvas = ref(null);
        const stepLineCanvas = ref(null);
        const refreshAnimation = ref(false);

        // Computed
        const progressItemClass = computed(() => ({
            'app-stepper-progress-item': true,
            ['is-passed']: step.value.index < activeStep.value.index,
            ['is-active']: step.value.index === activeStep.value.index
        }))

        // Watch
        watch(activeStep, () => {
            startStepCountAnimation();
            startStepLineAnimation();
        })

        // Methods
        function startStepCountAnimation() {
            if (stepCountStartTrigger && step.value.index < activeStep.value.index) {
                stepCountRive.play('Done')
            }

            if (stepCountStartTrigger && step.value.index > activeStep.value.index) {
                stepCountRive.reset({
                    canvas: stepCountCanvas.value,
                    src: require('@theme/assets/animations/rocket_mobile_step.riv'),
                    layout: new Layout({
                        alignment: 'Center',
                    }),
                    stateMachines: 'State Machine 1',
                    autoplay: true,
                })

                stepCountInputs = stepCountRive.stateMachineInputs('State Machine 1');
                stepCountStartTrigger = stepCountInputs.find(i => i.name === 'Start');
                doneCountStartTrigger = stepCountInputs.find(i => i.name === 'Done');

                stepCountRive.setTextRunValue("Run1", step.value.number.toString());
            }

            setTimeout(() => {
                if (step.value.index === activeStep.value.index) {
                    stepCountStartTrigger.value = true;
                    stepCountRive.pause('Done')
                    stepCountRive.play('Start')
                }
            }, 1300)
        }

        function startStepLineAnimation() {
            if (stepLineProcessTrigger && step.value.index < activeStep.value.index) {
                stepLineProcessTrigger.value = true;
                doneLineStartTrigger.value = true;
                stepLineRive.play('Done')
            }

            if (stepLineProcessTrigger && step.value.index > activeStep.value.index) {
                stepLineRive.reset({
                    canvas: stepLineCanvas.value,
                    src: require('@theme/assets/animations/line.riv'),
                    layout: new Layout({
                        alignment: 'Center',
                    }),
                    stateMachines: 'State Machine 1',
                    autoplay: true,
                });

                stepLineInputs = stepLineRive.stateMachineInputs('State Machine 1');
                stepLineProcessTrigger = stepLineInputs.find(i => i.name === 'process');
                doneLineStartTrigger = stepLineInputs.find(i => i.name === 'done');
            }

            setTimeout(() => {
                if (step.value.index === activeStep.value.index) {
                    stepLineRive.reset({
                        canvas: stepLineCanvas.value,
                        src: require('@theme/assets/animations/line.riv'),
                        layout: new Layout({
                            alignment: 'Center',
                        }),
                        stateMachines: 'State Machine 1',
                        autoplay: true,
                    });

                    stepLineInputs = stepLineRive.stateMachineInputs('State Machine 1');
                    stepLineProcessTrigger = stepLineInputs.find(i => i.name === 'process');
                    doneLineStartTrigger = stepLineInputs.find(i => i.name === 'done');

                    stepLineProcessTrigger.value = true;
                    stepLineRive.play('Process')
                }
            }, 1300)
        }

        function changeAnimationSize() {
            if (window.innerWidth < 576) {
                stepCountSize.value.width = 50;
                stepCountSize.value.height = 50;

                stepLineSize.value.width = 80;
                stepLineSize.value.height = 16;
            }

            if (window.innerWidth > 576) {
                stepCountSize.value.width = 70;
                stepCountSize.value.height = 70;

                stepLineSize.value.width = 165;
                stepLineSize.value.height = 16;
            }

            if (window.innerWidth > 992) {
                stepCountSize.value.width = 90;
                stepCountSize.value.height = 90;

                stepLineSize.value.width = 230;
                stepLineSize.value.height = 16;
            }

            if (stepCountRive) {
                stepCountRive.resizeToCanvas();
            }

            if (stepLineRive) {
                stepLineRive.resizeToCanvas();
            }
        }

        // Mounted
        onMounted(() => {
            changeAnimationSize()

            setTimeout(() => {
                stepCountRive = new Rive({
                    canvas: stepCountCanvas.value,
                    src: require('@theme/assets/animations/rocket_mobile_step.riv'),
                    layout: new Layout({
                        alignment: 'Center',
                    }),
                    stateMachines: 'State Machine 1',
                    autoplay: true,
                    onLoad: () => {
                        stepCountRive.setTextRunValue("Run1", step.value.number.toString());

                        stepCountInputs = stepCountRive.stateMachineInputs('State Machine 1');
                        stepCountStartTrigger = stepCountInputs.find(i => i.name === 'Start');
                        doneCountStartTrigger = stepCountInputs.find(i => i.name === 'Done');

                        if (step.value.index === activeStep.value.index) {
                            stepCountStartTrigger.value = true;
                            stepCountRive.play('Start')
                        }

                        if (step.value.index < activeStep.value.index) {
                            stepCountStartTrigger.value = true;
                            stepCountRive.play('Done')
                        }
                    },
                });

                stepLineRive = new Rive({
                    canvas: stepLineCanvas.value,
                    src: require('@theme/assets/animations/line.riv'),
                    layout: new Layout({
                        alignment: 'Center',
                    }),
                    stateMachines: 'State Machine 1',
                    autoplay: true,
                    onLoad: () => {
                        stepLineInputs = stepLineRive.stateMachineInputs('State Machine 1');
                        stepLineProcessTrigger = stepLineInputs.find(i => i.name === 'process');
                        doneLineStartTrigger = stepLineInputs.find(i => i.name === 'done');


                        if (step.value.index === activeStep.value.index) {
                            setTimeout(() => {
                                stepLineProcessTrigger.value = true;
                                stepLineRive.play('Process')
                            }, 1500)
                        }

                        if (step.value.index < activeStep.value.index) {
                            setTimeout(() => {
                                stepLineProcessTrigger.value = true;
                                doneLineStartTrigger.value = true;
                                stepLineRive.play('Done')
                            }, 1500)
                        }

                    },
                });
            }, 0)

            window.addEventListener('resize', changeAnimationSize)
        })

        onBeforeUnmount(() => {
            window.removeEventListener('resize', changeAnimationSize)
        })

        return {
            step,
            activeStep,
            progressItemClass,
            stepCountCanvas,
            stepLineCanvas,
            stepCountSize,
            stepLineSize,
            refreshAnimation,
        }
    }
}
</script>

<style lang="scss" scoped>
.app-stepper-progress-item {
    position: relative;
    z-index: 5;
    flex: 1 0 25%;
    max-width: 25%;

    &.is-passed {

        .app-stepper-progress-item__fill-line {
            right: -40%;
        }
    }

    &.is-active {

        .app-stepper-progress-item__title {
            color: $theme-primary-color;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 32px;
        left: 50%;
        right: -50%;
        height: 8px;
        background: $theme-dark-color;
        box-shadow: 0 0 2px 0 rgba($theme-black-color, .4) inset;
        z-index: -5;
        display: none;

        @media (min-width: $sm) {
            display: block;
        }

        @media (min-width: $lg) {
            top: 44px;
        }
    }

    &:last-of-type {
        &:before {
            display: none;
        }

        .app-stepper-progress-item__fill-line {
            display: none;
        }
    }

    &__fill-line {
        position: absolute;
        top: 19px;
        left: 39%;
        z-index: -2;
        transform: scale(0.5);
        transform-origin: 0 0;

        @media (min-width: $sm) {
            top: 28px;
        }

        @media (min-width: $lg) {
            top: 40px;
        }
    }

    &__count {
        position: relative;
        display: block;
        margin: 0 auto;
        transform: scale(0.5);
        transform-origin: 0 0;

        @media (min-width: $sm) {
            transform-origin: 50% 0;
        }
    }

    &__title {
        font-size: 1rem;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;
        color: $theme-gray-color;
        transition: $transition-time-main;
        transition-delay: 1.3s;
        display: none;

        @media (min-width: $sm) {
            display: block;
        }
    }
}
</style>
