<template>
    <div class="migration-fail-confirm">
        <app-animation-button button-text="Try again" @click="tryAgain"/>
    </div>
</template>

<script>
import AppAnimationButton from "@/components/app-animation-button/index.vue";

export default {
    name: 'MigrationFailConfirm',
    emits: ['tryAgain'],
    components: {
        AppAnimationButton
    },
    setup(_, {emit}) {
        // Methods
        function tryAgain() {
            emit('tryAgain')
        }

        return {
            tryAgain
        }
    }
}
</script>

<style lang="scss" scoped>
.migration-fail-confirm {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 40px 0 32px;

    :deep {
        .animation-button {
            margin-bottom: 60px;
        }
    }
}
</style>
