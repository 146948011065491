<template>
    <div class="migration-step-form-divider">
        <span class="migration-step-form-divider__title">or continue with</span>
        <div class="migration-step-form-divider__line"></div>
    </div>
</template>

<script>
export default {
    name: 'MigrationStepFormDivider'
}
</script>

<style lang="scss" scoped>

</style>
