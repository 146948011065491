<template>
    <div class="migration-step-form-social">
        <div class="d-none d-lg-flex">
            <button v-for="buttonItem in buttonList"
                    :key="buttonItem"
                    :disabled="disabled && buttonItem.canDisabled"
                    type="button"
                    @click="continueWith(buttonItem.methodEmit)"
                    :class="buttonItem.className"
            >
                <img :src="buttonItem.icon" :alt="buttonItem.iconAlt">
                <span>{{ buttonItem.title }}</span>
            </button>
        </div>
        <div class="d-none d-md-flex d-lg-none">
            <button v-for="buttonItem in buttonList"
                    :key="buttonItem"
                    :disabled="disabled && buttonItem.canDisabled"
                    type="button"
                    @click="continueWith(buttonItem.methodEmit)"
                    :class="buttonItem.className"
            >
                <img :src="buttonItem.icon" :alt="buttonItem.iconAlt">
                <span>Continue</span>
            </button>
        </div>
        <div class="d-flex d-md-none">
            <button v-for="buttonItem in buttonList"
                    :key="buttonItem"
                    :disabled="disabled && buttonItem.canDisabled"
                    type="button"
                    @click="continueWith(buttonItem.methodEmit)"
                    :class="buttonItem.className"
            >
                <img :src="buttonItem.icon" :alt="buttonItem.iconAlt">
            </button>
        </div>
    </div>
</template>

<script>
import {ref} from "vue";

export default {
    name: 'MigrationStepFormSocial',
    emits: ['continueWithApple', 'continueWithGoogle', 'continueWithEmail'],
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {
        // Props
        const {disabled} = props;


        // Data
        const buttonList = ref([
            {
                id: 0,
                icon: require('@/assets/images/apple-sign-in-icon.svg'),
                iconAlt: 'apple sign in icon',
                title: 'Continue with Apple',
                methodEmit: 'continueWithApple',
                name: 'apple',
                className: `migration-step-form-social__btn migration-step-form-social__btn--apple`,
                canDisabled: true,
            },
            {
                id: 1,
                icon: require('@/assets/images/google-sign-in-icon.svg'),
                iconAlt: 'google sign in icon',
                title: 'Continue with Google',
                methodEmit: 'continueWithGoogle',
                name: 'google',
                className: `migration-step-form-social__btn migration-step-form-social__btn--google`,
                canDisabled: true,
            },
        ])

        // Methods
        function continueWith(continueWith) {
            emit(continueWith);
        }

        return {
            buttonList,
            continueWith,
        }
    }
}
</script>

<style lang="scss" scoped>
button {
    &[disabled] {
        pointer-events: none;
        user-select: none;
        opacity: .65;
    }
}
</style>
