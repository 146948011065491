<template>
    <div class="form-input" :class="{'is-icon': icon.length, 'is-disabled': disabled, 'is-error': error}">
        <div v-if="$slots.default">
            <slot/>
        </div>

        <input v-else
               :id="input_id"
               :type="computedType"
               :inputmode="inputmode"
               :value="modelValue"
               :disabled="disabled"
               :maxlength="maxlength"
               :placeholder="placeholder"
               :readonly="readonly"
               @focus="handleFocus"
               @blur="handleBlur"
               @input="handleChange($event.target.value)">
        <svg width="24" height="24" class="form-input__icon">
            <use :xlink:href="`#${icon}`"/>
        </svg>

        <div v-if="isPassword" class="form-input__show-pass-icon" @click="showPassword">
            <svg width="24" height="24">
                <use xlink:href="#form-input-pass-open" v-if="!isShowPassword"/>
                <use xlink:href="#form-input-pass-close" v-else/>
            </svg>
        </div>

        <password-policy v-if="showPasswordPolicy" :password="modelValue"/>

        <div class="form-input__error-text" v-if="error">{{ error }}</div>
    </div>
</template>

<script>
import PasswordPolicy from "@/components/app-form-components/password-policy.vue";

export default {
    name: '',
    components: {PasswordPolicy},
    props: {
        input_id: {
            type: String
        },
        modelValue: {
            required: false
        },
        type: {
            type: String,
            default: 'text'
        },
        inputmode: {
            type: String,
            default: 'text'
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        error: {
            default: false
        },
        icon: {
            type: String,
            default: ''
        },
        maxlength: {
            default: 100
        },
        placeholder: {
            default: ''
        },
        readonly: {
            type: Boolean,
            default: false
        },
        isNotPassword: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isShowPassword: false,
            isSHowPasswordPolicy: false,
        }
    },
    computed: {
        isPassword() {
            return this.type === 'password' && !this.isNotPassword
        },
        computedType() {
            if (this.type === 'password') {
                return this.isShowPassword ? 'text' : 'password'
            } else {
                return this.type
            }
        },
        showPasswordPolicy() {
            return this.isPassword && this.isSHowPasswordPolicy;
        }
    },
    methods: {
        handleChange(value) {
            this.$emit('update:modelValue', value)
        },
        handleFocus() {
            this.isSHowPasswordPolicy = true;
            this.$emit('focus')
        },
        handleBlur(value) {
            this.isSHowPasswordPolicy = false;
            this.$emit('blur', value)
        },
        showPassword() {
            this.isShowPassword = !this.isShowPassword
        },
    },
}
</script>

<style lang="scss" scoped>
.form-input {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
    z-index: 5;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: $theme-dark-color;
        height: 48px;
        border-radius: 4px;
        z-index: -2;
    }

    > div:not(.form-input__show-pass-icon) {
        width: 100%;
    }

    &.is-icon {
        padding-left: 40px;
    }

    &.is-error {

        input {

            &::placeholder {
                color: $danger;
            }
        }

        .form-input__icon {
            fill: $danger;
        }

        .form-input__show-pass-icon {
            fill: $danger;
        }
    }

    input {
        width: 100%;
        height: auto;
        padding: 0;
        border: none;
        outline: none;
        background: transparent;
        font-size: .75rem;
        color: $white;
        font-weight: 400;

        @media (min-width: $sm) {
            font-size: .875rem;
        }

        &::placeholder {
            color: $theme-gray-200-color;
        }

        &:-webkit-autofill{
            -webkit-text-fill-color: $theme-dark-color !important;
        }
    }

    &__icon {
        position: absolute;
        top: 24px;
        left: 8px;
        transform: translateY(-50%);
        z-index: 5;
        fill: white;
    }

    &__show-pass-icon {
        position: absolute;
        top: 27px;
        right: 8px;
        transform: translateY(-50%);
        z-index: 5;
        fill: white;
        cursor: pointer;
    }

    &__error-text {
        margin-top: 20px;
        margin-left: -55px;
        margin-bottom: -25px;
        font-size: .75rem;
        color: $danger;
    }
}
</style>
