<template>
    <section class="generate">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="generate-container">
                        <app-generate-token-login-form v-if="isShowLoginForm" @onLogin="handleLogin"/>
                        <app-generate-token-form v-else />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {ref} from "vue";

import AppGenerateTokenLoginForm from "@/components/app-generate-token-form/login-form.vue";
import AppGenerateTokenForm from "@/components/app-generate-token-form/index.vue";

export default {
    name: 'TokenGeneratePage',
    components: {AppGenerateTokenForm, AppGenerateTokenLoginForm},
    setup() {
        // Data
        const isShowLoginForm = ref(true)

        // Methods
        function handleLogin() {
            isShowLoginForm.value = !isShowLoginForm.value
        }

        return {
            isShowLoginForm,
            handleLogin,
        }
    }
}
</script>

<style lang="scss" scoped>
.generate-container {
    position: relative;
    padding: 16px;
    background: $theme-darkness-light-color;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba($theme-black-color, .1);
    min-height: 240px;
    z-index: 10;

    @media (min-width: $sm) {
        padding: 24px 32px;
    }

    @media (min-width: $lg) {
        padding: 48px 65px;
    }

    :deep {

        h2 {
            font-size: 1.375rem;
            font-weight: 700;
            color: $theme-primary-color;
            margin-bottom: 24px;

            @media (min-width: $sm) {
                font-size: 1.6rem;
            }

            @media (min-width: $lg) {
                font-size: 2rem;
            }
        }
    }
}
</style>
