<template>
    <div class="mobile-service-card" :class="{'is-checked': isChecked}" @click="handleClickCard">
        <div class="mobile-service-card-row">
            <div class="mobile-service-card-header">
                <h3 class="mobile-service-card__name">{{ serviceData['plan-name'] || 'Starter' }}</h3>
                <div class="mobile-service-card-price">
                    <span class="mobile-service-card-price__current">$ {{ (serviceData.price/100).toFixed(2) }}</span>
                    <span class="mobile-service-card-price__old" v-if="oldPrice">$ {{ (oldPrice/100).toFixed(2) }}</span>
                </div>
            </div>
            <div class="mobile-service-card__info mobile-service-card__info--header">
                {{serviceData['plan-description'] }}
            </div>
        </div>
        <div class="mobile-service-card-row">
            <div class="mobile-service-card-table">
                <div class="mobile-service-card-table-row">
                    <span class="mobile-service-card-table__name">Mobile number</span>
                    <span class="mobile-service-card-table__value">{{ serviceData.phone }}</span>
                </div>
                <div class="mobile-service-card-table-row">
                    <span class="mobile-service-card-table__name">SIM ID</span>
                    <span class="mobile-service-card-table__value">{{ serviceData.simid }}</span>
                </div>
            </div>
        </div>
        <div class="mobile-service-card-row">
            <div class="mobile-service-card-table">
                <div class="mobile-service-card-table-row">
                    <span class="mobile-service-card-table__name">Data</span>
                    <span class="mobile-service-card-table__value">{{ serviceData['plan-data-unlimited'] ? 'Unlimited' : serviceData['plan-data'] + ' MB' }}</span>
                </div>
            </div>
            <div class="mobile-service-card__info" v-if="serviceData['plan-data-info']">
                {{ serviceData['plan-data-info'] }}
            </div>
            <div class="mobile-service-card-table">
                <div class="mobile-service-card-table-row">
                    <span class="mobile-service-card-table__name">Calls to NZ & Aussie</span>
                    <span class="mobile-service-card-table__value">{{ serviceData['plan-mins-unlimited'] ? 'Unlimited' : 'Limited' }}</span>
                </div>
                <div class="mobile-service-card-table-row">
                    <span class="mobile-service-card-table__name">SMS to NZ & Aussie</span>
                    <span class="mobile-service-card-table__value">{{ serviceData['plan-txts-unlimited'] ? 'Unlimited' : 'Limited' }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, ref, toRefs} from "vue";

export default {
    name: 'MobileServiceCard',
    props: {
        serviceData: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        // Props
        const {serviceData} = toRefs(props);

        // Data
        const isChecked = ref(true)

        // Computed
        const oldPrice = computed(() => {
            if(serviceData.value.discount === 0) {
                return 0;
            }
            return serviceData.value.discount + serviceData.value.price
        })

        // Methods
        function handleClickCard() {
            isChecked.value = !isChecked.value;
        }

        return {
            serviceData,
            isChecked,
            oldPrice,
            handleClickCard,
        }
    }
}
</script>

<style lang="scss" scoped>
.mobile-service-card {
    position: relative;
    width: 100%;
    padding: 16px 8px;
    border-radius: 6px;
    border: 2px solid $theme-dark-light-color;
    background: $theme-dark-color;
    text-align: left;
    height: 100%;
    transition: $transition-time-main;
    cursor: pointer;

    @media (min-width: $sm) {
        padding: 16px;
    }

    &.is-checked {
        border-color: $theme-secondary-color;
    }

    &-row {
        padding-bottom: 16px;
        margin-bottom: 12px;
        border-bottom: 1px solid $theme-gray-color;

        &:last-of-type {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    &-price {
        display: flex;
        align-items: center;

        &__old {
            font-size: .750rem;
            font-weight: 400;
            color: $theme-gray-color;

            @media (min-width: $sm) {
                font-size: 1rem;
            }
        }

        &__current {
            font-size: 1rem;
            font-weight: 600;
            color: $theme-primary-color;
            margin-right: 8px;

            @media (min-width: $sm) {
                font-size: 1.5rem;
            }
        }
    }

    &-table {
        position: relative;
        width: 100%;

        &-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &__name {
            font-weight: 400;
            font-size: .75rem;

            @media (min-width: $lg) {
                font-size: .875rem;
            }
        }

        &__value {
            font-weight: 600;
            font-size: .875rem;
            text-align: right;
        }
    }

    &__name {
        font-size: 1.125rem;
        font-weight: 600;

        @media (min-width: $sm) {
            font-size: 1.25rem;
        }
    }

    &__info {
        margin-bottom: 12px;
        font-size: .75rem;
        font-weight: 400;
        color: $white;

        @media (min-width: $lg) {
            font-size: .875rem;
        }

        &--header {
            font-size: .75rem;
            margin-bottom: 0;

            @media (min-width: $lg) {
                font-size: 1rem;
            }
        }
    }
}
</style>
