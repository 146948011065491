<template>
    <header class="app-header">
        <div class="container">
            <div class="row justify-content-between align-items-center">
                <div class="col-auto">
                    <router-link to="/" class="logo">
                        <img src="@theme/assets/logo.png" class="d-none d-sm-block" alt="logo">
                        <img src="@theme/assets/logo-mobile.png" class="d-sm-none" alt="logo">
                    </router-link>
                </div>
                <div class="col-auto">
                    <div class="d-flex align-items-center">
                        <h3 class="header-title">
                            Move to Rocket Mobile
                        </h3>
                        <button v-if="isShowLogoutButton"
                                type="button"
                                class="button-logout"
                                @click="logout"
                        >
                            <svg width="24" height="24">
                                <use xlink:href="#logout-icon"></use>
                            </svg>
                            <span class="d-none d-sm-inline">Logout</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {computed} from "vue";

export default {
    name: 'AppHeader',
    setup() {
        // Store
        const store = useStore();

        // Computed
        const activeStep = computed(() => {
            return store.getters['step/activeStep']
        })

        const isLogin = computed(() => {
            return store.getters['auth/isLogin']
        })

        const isShowLogoutButton = computed(() => {
            return isLogin.value && activeStep.value?.id !== 3;
        })

        // Data
        const router = useRouter();

        // Methods
        function logout() {
            store.dispatch('auth/logout');
            router.push({name: 'Create'})
        }

        return {
            isLogin,
            activeStep,
            isShowLogoutButton,
            logout,
        }
    }
}
</script>

<style lang="scss" scoped>
.app-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 16px 0;

    @media (min-width: $sm) {
        padding: 24px 0;
    }

    .container {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: -24px;
            border-bottom: 1px solid $dark;
        }
    }

    .logo {

        img {
            max-width: 260px;
        }
    }

    .header-title {
        font-weight: 400;
        font-size: 1rem;

        @media (min-width: $sm) {
            font-size: 1.125rem;
        }
    }

    .button-logout {
        display: inline-flex;
        align-items: center;
        padding: 0;
        margin: 0;
        margin-left: 16px;
        outline: none;
        color: $theme-primary-color;
        font-size: 1rem;
        font-weight: 600;
        transition: $transition-time-main;

        svg {
            fill: $theme-primary-color;
            margin-right: 8px;
            transition: $transition-time-main;
        }

        &:hover {
            color: $theme-secondary-color;

            svg {
                fill: $theme-secondary-color;
            }
        }
    }
}
</style>
