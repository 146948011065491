<template>
    <form @submit="submitCreateForm" class="migration-step-form">
        <div class="migration-step-form-body">
            <div class="row">
                <div class="col-sm-6">
                    <form-input v-model="formData.email"
                                type="email"
                                placeholder="Email"
                                :error="inputErrors.fields.email"
                                icon="form-input-email"/>
                </div>
                <div class="col-sm-6">
                    <form-input v-model="formData.pass"
                                type="password"
                                placeholder="Password"
                                :error="inputErrors.fields.passnew"
                                icon="form-input-lock"/>
                </div>
            </div>
        </div>
    </form>

    <p class="migration-step-form__change-type mt-0">
        Don't have an account?
        <button type="button" @click="switchFormType">Create a new one</button>
    </p>

    <app-animation-button @click="submitCreateForm"/>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";

import {toast} from "vue3-toastify";

import FormInput from "@/components/app-form-components/form-input.vue";
import FormCheckbox from "@/components/app-form-components/form-checkbox.vue";
import UserDetailsValidator from "@/validators/user-details-validator";
import AppAnimationButton from "@/components/app-animation-button/index.vue";

export default {
    name: 'MigrationCreateSignupForm',
    emits: ['submitForm', 'switchFormType', 'startSubmitForm', 'doneSubmitForm'],
    components: {
        AppAnimationButton,
        FormInput,
        FormCheckbox,
    },
    props: {
        preData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    setup(props, {emit}) {
        // Props
        const {preData} = props;

        // Store
        const store = useStore();

        // Data
        const formData = ref({
            email: '',
            pass: '',
        })

        const inputErrors = ref({fields: {},});

        // Computed
        const customerToken = computed(() => {
            return store.getters['firebase_auth/customer_token']
        })

        // Watch
        watch(preData, () => {
            prefillUserDataFromSignUp()
        })


        // Methods
        function formValidation() {
            let isValid = true;

            inputErrors.value.fields = {};

            const values = {
                email: {
                    value: formData.value.email,
                },
                passnew: {
                    value: formData.value.pass,
                },
            }

            for (const key in values) {
                if (UserDetailsValidator.isRuleExists(key)) {
                    if (UserDetailsValidator.isInvalid(key, values[key].value, values[key])) {
                        inputErrors.value.fields[key] = UserDetailsValidator.getError()

                        isValid = false
                    }
                }
            }

            return isValid
        }

        function accessSubmitForm() {
            emit('submitForm')
        }

        function switchFormType() {
            emit('switchFormType', true)
        }

        function submitCreateForm() {
            if (formValidation()) {
                emit('startSubmitForm');

                store.dispatch('firebase_auth/FirebaseSignInEmailPassword', formData.value)
                    .then(() => store.dispatch('auth/attemptUser', customerToken.value))
                    .then(() => accessSubmitForm())
                    .catch((error) => {
                        toast.error(error.message)
                    })
                    .finally(() => emit('doneSubmitForm'))
            }
        }

        function prefillUserDataFromSignUp() {
            formData.value = {
                email: preData.email,
                pass: preData.passnew,
            }
        }

        function prefillUserData() {
            store.dispatch('auth/prefillUserData', customerToken.value)
                .then(({data}) => {
                    formData.value.email = data.email;
                    formData.value.firstname = data.firstname;
                    formData.value.lastname = data.lastname;
                })
        }

        onMounted(() => {
            prefillUserDataFromSignUp();
            prefillUserData();
        })

        return {
            formData,
            inputErrors,
            submitCreateForm,
            accessSubmitForm,
            switchFormType,
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
