const state = {
    Stripe: null,
    stripe_initialized: null,
}

const getters = {
    client_secret: state => state.client_secret,
}

const mutations = {
    saveStripe(state, Stripe) {
        state.Stripe = Stripe
        state.stripe_initialized = null
    },
    stripeInitialized(state, promise) {
        state.stripe_initialized = promise
    },
}

const actions = {
    stripeInit({state, commit}, config) {
        if (state.stripe_initialized) {
            return state.stripe_initialized
        } else {
            if (state.Stripe) {
                return Promise.resolve(state.Stripe)
            }
            if ('Stripe' in window) {
                const Stripe = window.Stripe(
                    process.env.VUE_APP_STRIPE_API_KEY,
                );

                commit('saveStripe', Stripe)
                return Promise.resolve(state.Stripe)
            } else {
                let stripe_initialized = new Promise((resolve, reject) => {
                    let script = document.createElement('script')
                    script.src = 'https://js.stripe.com/v3/'

                    script.onreadystatechange = script.onload = () => {
                        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
                            const Stripe = window.Stripe(
                                process.env.VUE_APP_STRIPE_API_KEY,
                            );

                            commit('saveStripe', Stripe)
                            resolve(state.Stripe)
                        }
                    }

                    script.onerror = () => {
                        reject(new Error('Error by Stripe loading'))
                    }

                    document.getElementsByTagName('head')[0].appendChild(script)
                })

                commit('stripeInitialized', stripe_initialized)

                return stripe_initialized
            }
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
