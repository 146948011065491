import axios from "axios";

export default {
    namespaced: true,

    state: {
        customer_uuid: localStorage.getItem('account_customer_uuid'),
        auth_check_jwt: '',
    },

    getters: {
        customer_uuid: (state) => state.customer_uuid,
        auth_check_jwt: (state) => state.auth_check_jwt,
        isLogin: (state) => !!state.customer_uuid && state.customer_uuid !== '',
    },

    actions: {
        attemptUser({commit}, token) {
            return axios.post('migration/v1/attempt', {token})
                .then(({data}) => {
                    commit('SAVE_CUSTOMER_UUID', data.account);
                    return data.account;
                })
        },
        updateUser({commit}, user) {
            return axios.put(`customer/${user.UUID}`, user.payload)
        },
        authCheck({commit}, data) {
            data.IdentityProvider = 'LOCAL';
            data.IncludeRoles = true;
            data.SPID = Number(process.env.VUE_APP_SPID);

            return axios.post(`xvne/api/v2/${data.SPID}/auth_check`, data)
                .then(({data}) => {
                    commit('SAVE_AUTH_CHECK_JWT', data.JWT);
                    return data;
                })
        },
        prefillUserData({commit}, token) {
            return axios.post('migration/v1/prefill', {token})
        },
        logout({commit, dispatch}) {
            commit('LOGOUT');

            return dispatch('firebase_auth/FirebaseLogout', {}, {root: true})
        },
    },

    mutations: {
        SAVE_CUSTOMER_UUID(state, customerUUID) {
            localStorage.setItem('account_customer_uuid', customerUUID)
            state.customer_uuid = customerUUID;
        },
        SAVE_AUTH_CHECK_JWT(state, JWT) {
            state.auth_check_jwt = JWT;
        },
        LOGOUT(state) {
            localStorage.removeItem('account_customer_uuid')
            state.customer_uuid = null;
        }
    },
};
