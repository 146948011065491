<template>
    <app-svgs />
    <app-header/>
    <main>
        <router-view />
    </main>
</template>

<script>
import AppSvgs from "@theme/components/app-svgs/index.vue";
import AppHeader from "@/components/app-header/index.vue";
import Home from "@/views/home/index.vue";

export default {
    components: {
        AppSvgs,
        AppHeader,
        Home
    },
}
</script>

<style lang="scss">
#app {

    main {
        min-height: 100vh;
        padding: 85px 0 50px;

        @media (min-width: $sm) {
            padding: 105px 0 50px;
        }

        @media (min-width: $lg) {
            padding: 115px 0 50px;
        }
    }
}
</style>
