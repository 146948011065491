import axios from "axios";

export default {
    namespaced: true,

    state: {},

    getters: {},

    actions: {
        // Mock methods
        getDetails({rootGetters}) {
            const uuid = rootGetters['auth/customer_uuid'];

            return axios.get(`/api/frontend/v1/migration/${uuid}`)
        },
        getPlanList({rootGetters}) {
            const uuid = rootGetters['auth/customer_uuid']

            return axios.get(`/migration/v1/account/${uuid}/progress`)
        },
        getBalance({rootGetters}) {
            const uuid = rootGetters['auth/customer_uuid']

            return axios.get(`/migration/v1/account/${uuid}/balance`)
        },
    },

    mutations: {},
};
