import {db} from "@/utils/firebase";

const COLLECTION_CUSTOMERS = 'customers';
const COLLECTION_PAYMENT_METHODS = 'payment_methods';

export default {
    namespaced: true,

    state: {
        account_customer: null,
        customer_payment_methods: null,
    },

    getters: {
        account_customer: (state) => state.account_customer,
        customer_payment_methods: (state) => state.customer_payment_methods,
    },

    actions: {
        getAccountCustomerByUUID({commit}, uuid) {
            return db.collection(COLLECTION_CUSTOMERS)
                .doc(uuid)
                .get()
                .then((customer) => {
                    commit('SAVE_ACCOUNT_CUSTOMER', customer.data());
                    return customer.data();
                })
        },
        getCustomerPaymentMethodsByUUID({commit}, uuid) {
            return db.collection(COLLECTION_CUSTOMERS)
                .doc(uuid)
                .get()
                .then((customer) => {
                    return customer.ref
                        .collection(COLLECTION_PAYMENT_METHODS)
                        .get()
                })
                .then((payment_methods) => {
                    const account_payment_methods = payment_methods.docs.map((doc) => doc.data());

                    commit('SAVE_CUSTOMER_PAYMENT_METHODS', account_payment_methods);
                    return account_payment_methods;
                })
        },
    },

    mutations: {
        SAVE_ACCOUNT_CUSTOMER(state, account_customer) {
            state.account_customer = account_customer;
        },
        SAVE_CUSTOMER_PAYMENT_METHODS(state, customer_payment_methods) {
            state.customer_payment_methods = customer_payment_methods;
        },
    },
};
