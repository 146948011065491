<template>
    <section class="intro">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <app-stepper :steps="steps" :active-step="activeStep">
                        <div slot-scope="{activeStep}">
                            <router-view v-bind="activeStep.props"/>
                        </div>
                    </app-stepper>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";

import stepsInfo from '@/views/home/data/steps.json'

import AppStepper from "@/components/app-stepper/index.vue";
import MigrationCreate from "@/components/app-migration-steps/migration-create/index.vue";
import MigrationPayment from "@/components/app-migration-steps/migration-payment/index.vue";
import MigrationConfirmation from "@/components/app-migration-steps/migration-confirmation/index.vue";
import MigrationDone from "@/components/app-migration-steps/migration-done/index.vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

export default {
    name: 'Home',
    components: {
        AppStepper,
        MigrationCreate,
        MigrationPayment,
        MigrationConfirmation,
        MigrationDone,
    },
    setup() {
        // Store
        const store = useStore();

        // Data
        const route = useRoute();
        const steps = ref(stepsInfo);
        const stepsData = ref({});
        const activeStep = ref(stepsInfo[0]);

        // Computed
        const activeStepIndex = computed(() => {
            if (activeStep) {
                return steps.value.findIndex(step => step.id === activeStep.value.id)
            }
            return 1
        })

        // Watch
        watch(route, (value) => {
            updateTokenFromUrl();
            changeActiveStep(value.name);
        })

        watch(activeStep, () => {
            saveActiveStepInStore();
        })

        // Methods
        function nextStep() {
            if (activeStepIndex !== steps.length + 1) {
                activeStep.value = steps.value[activeStepIndex.value + 1]
            }
        }

        function saveStepsInStore() {
            store.dispatch('step/saveSteps', steps.value);
        }

        function saveActiveStepInStore() {
            store.dispatch('step/changeActiveStep', activeStep.value);
        }

        function changeActiveStep(name) {
            activeStep.value = stepsInfo.find((step) => step.name === name) || stepsInfo[0];
        }

        function updateTokenFromUrl() {
            const token = route.query.token;

            if (token) {
                store.commit('firebase_auth/SAVE_CURRENT_TOKEN', token)
            }
        }

        // Mounted
        onMounted(() => {
            updateTokenFromUrl();
            changeActiveStep(route.name);
            saveStepsInStore();
            saveActiveStepInStore();
        })

        return {
            steps,
            activeStep,
            stepsData,
            activeStepIndex,
            nextStep,
        }
    },
}
</script>
