<template>
    <ul class="password-policy">
        <li class="password-policy-item" :class="{'is-error': !has10Chars}">
            <svg width="24" height="24" class="password-policy__icon">
                <use :xlink:href="validationStatusIcon(has10Chars)"></use>
            </svg>
            <span class="password-policy__value">10 or more characters</span>
        </li>

        <li class="password-policy-item" :class="{'is-error': !hasUpperAndLower}">
            <svg width="24" height="24" class="password-policy__icon">
                <use :xlink:href="validationStatusIcon(hasUpperAndLower)"></use>
            </svg>
            <span class="password-policy__value">UPPER & lower case letters</span>
        </li>

        <li class="password-policy-item" :class="{'is-error': !hasNumberOrSymbol}">
            <svg width="24" height="24" class="password-policy__icon">
                <use :xlink:href="validationStatusIcon(hasNumberOrSymbol)"></use>
            </svg>
            <span class="password-policy__value">At least one number or symbol</span>
        </li>
    </ul>
</template>

<script>
import {computed, toRefs} from "vue";

export default {
    name: 'PasswordPolicy',
    props: {
        password: {
            type: String,
            default: ''
        },
    },
    setup(props) {
        // Props
        const {password} = toRefs(props);

        // Computed
        const has10Chars = computed(() => {
            return password.value.length >= 10
        })

        const hasUpperAndLower = computed(() => {
            const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
            const lowercase = "abcdefghijklmnopqrstuvwxyz"
            return passwordContainsCount(password.value, uppercase) > 0 && passwordContainsCount(password.value, lowercase) > 0
        })

        const hasNumberOrSymbol = computed(() => {
            const digitsOrSplChars = "0123456789!@#$%&*()"
            return passwordContainsCount(password.value, digitsOrSplChars) > 0
        })

        // Methods
        function passwordContainsCount(password, allowedChars) {
            let count = 0

            for (let i = 0; i < password.length; i++) {
                let char = password.charAt(i);
                if (allowedChars.indexOf(char) >= 0) {
                    count++;
                }
            }

            return count
        }

        function validationStatusIcon(isValid) {
            return isValid ? '#check-circle-icon' : '#exclam-circle-icon';
        }

        return {
            has10Chars,
            hasUpperAndLower,
            hasNumberOrSymbol,
            validationStatusIcon,
        }

    }
}
</script>

<style lang="scss" scoped>
.password-policy {
    position: absolute;
    top: 60px;
    left: 20px;
    margin: 0;
    padding: 8px;
    border: 1px solid $theme-dark-light-color;
    background: $theme-darkness-color;
    border-radius: 0 4px 4px 4px;
    z-index: 200;

    &-item {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        &.is-error {
            .password-policy__icon {
                fill: $danger;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__icon {
        line-height: 1;
        margin-right: 4px;
        fill: $theme-secondary-color;
    }

    &__value {
        font-size: .75rem;
        color: $theme-gray-300-color;

        @media (min-width: $sm) {
            font-size: .875rem;
        }
    }
}
</style>
