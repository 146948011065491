export default {
    namespaced: true,

    state: {
        steps: null,
        activeStep: null,
    },

    getters: {
        steps: (state) => state.steps,
        activeStep: (state) => state.activeStep,
    },

    actions: {
        saveSteps({commit}, steps) {
            commit('SAVE_STEPS', steps);
            return steps;
        },
        changeActiveStep({commit}, activeStep) {
            commit('SAVE_ACTIVE_STEP', activeStep);
            return activeStep;
        },
    },

    mutations: {
        SAVE_STEPS(state, steps) {
            state.steps = steps;
        },
        SAVE_ACTIVE_STEP(state, activeStep) {
            state.activeStep = activeStep;
        },
    },
};
