<template>
    <div class="mobile-service-list">
        <h4 class="mobile-service-list__title">
            Your mobile service<span v-if="serviceList.length > 1">s</span> being moved to Rocket Mobile:
        </h4>
        <carousel v-bind="carouselOptions" :breakpoints="carouselBreakpoints">
            <slide v-for="serviceData in serviceList" :key="serviceData.phone_number">
                <mobile-service-card :service-data="serviceData"/>
            </slide>

            <template #addons>
                <navigation/>
                <pagination v-if="serviceList.length > 1"/>
            </template>
        </carousel>
    </div>
</template>

<script>
import {ref} from "vue";

import mobileServiceInfo from './../data/mobile-services.json';

import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';
import MobileServiceCard from "@/components/app-migration-steps/migration-confirmation/mobile-services/card.vue";

export default {
    name: 'MobileServiceList',
    components: {
        MobileServiceCard,
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    props: {
        serviceList: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        // Props
        const {serviceList} = props;

        // Data
        const carouselOptions = {
            itemsToShow: 1.1,
            itemsToScroll: 1,
            snapAlign: 'start',
        };
        const carouselBreakpoints = {
            768: {
                itemsToShow: 2.1,
                itemsToScroll: 2,
            },
        };


        return {
            serviceList,
            carouselOptions,
            carouselBreakpoints,
        }
    }
}
</script>

<style lang="scss" scoped>
.mobile-service-list {
    position: relative;
    width: 100%;
    margin-bottom: 84px;

    &__title {
        font-size: .875rem;
        color: $theme-secondary-color;
        margin-bottom: 8px;

        @media (min-width: $sm) {
            font-size: 1rem;
        }
    }

    :deep {
        .carousel {
            margin: 0 -5px;

            @media (min-width: $lg) {
                margin: 0 -15px;
            }

            &__slide {
                padding: 0 5px;

                @media (min-width: $lg) {
                    padding: 0 15px;
                }
            }

            &__pagination {
                position: absolute;
                right: 15px;
                bottom: -44px;

                &-item {
                    width: 14px;
                    height: 14px;
                    margin: 0 6px;
                }

                &-button {
                    width: 14px;
                    height: 14px;
                    padding: 0;
                    margin: 0;
                    border-radius: 50%;
                    background: $theme-gray-300-color;

                    &::after {
                        display: none;
                    }

                    &--active {
                        background: $theme-secondary-color;
                    }
                }
            }

            &__prev,
            &__next {
                top: auto;
                bottom: -64px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin: 0;
                background: $theme-gray-300-color;
            }

            &__prev {
                left: 15px;
            }

            &__next {
                left: 65px;
            }
        }
    }
}
</style>
