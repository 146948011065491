<template>
    <div class="app-stepper-content-header">
        <h2 class="app-stepper-content__title">{{ stepContentData.title }}</h2>
        <p class="app-stepper-content__subtitle">{{ stepContentData.subtitle }}</p>
    </div>
</template>

<script>
import {toRefs} from "vue";

export default {
    name: 'AppStepperContentHeader',
    props: {
        stepContentData: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        // Props
        const {stepContentData} = toRefs(props);

        return {
            stepContentData
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
