<template>
    <div :class="loaderClasses">
        <div class="app-loader-wrapper">
            <img src="@theme/assets/app-loader-line-big.svg" class="app-loader__line app-loader__line--big">
            <img src="@theme/assets/app-loader-line-small.svg" class="app-loader__line app-loader__line--small">
        </div>
        <div class="app-loader__mask"></div>
    </div>
</template>

<script>
import {computed} from "vue";

export default {
    name: 'AppLoader',
    props: {
        fullScreen: {
            type: Boolean,
            default: false
        },
        fullBlock: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        // Props
        const {fullScreen, fullBlock} = props;

        // Computed
        const loaderClasses = computed(() => ({
            'app-loader': true,
            [`is-full-screen`]: fullScreen,
            [`is-full-block`]: fullBlock,
        }))

        return {
            loaderClasses,
        }
    }
}
</script>

<style lang="scss" scoped>
.app-loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    &.is-full-screen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .app-loader__mask {
            display: block;
        }
    }

    &.is-full-block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .app-loader__mask {
            display: block;
        }
    }

    &-wrapper {
        position: relative;
        width: 160px;
        height: 160px;
        z-index: 5;
    }

    &__line {

        &--big {
            position: absolute;
            top: 0;
            left: 0;
            width: 160px;
            height: 160px;
            animation: loaderBigLineRotation 3s linear infinite;
        }

        &--small {
            position: absolute;
            top: 37px;
            left: 37px;
            width: 88px;
            height: 88px;
            transform-origin: center center;
            animation: loaderSmallLineRotation 2s linear infinite;
        }

        @keyframes loaderSmallLineRotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        @keyframes loaderBigLineRotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(-360deg);
            }
        }
    }

    &__mask {
        @extend %posABS;
        background: rgba($theme-black-color, .3);
        display: none;
        z-index: -2;
    }
}
</style>
