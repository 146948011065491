import axios from "axios";

export default {
    namespaced: true,

    state: {},

    getters: {},

    actions: {
        confirmMigration({rootGetters}) {
            const uuid = rootGetters['auth/customer_uuid']

            return axios.post(`/migration/v1/account/${uuid}/confirmation`)
        },
    },

    mutations: {},
};
