/**
 * Request interceptor for axios:
 * - adds an authorization token in the firebase_identity header when sending each request
 *
 * @param {*} Vue
 * @param {*} axios
 * @param {*} store
 * @param {*} router
 */
const axios_config = {
    timeout: 10000,
    maxRedirects: 1,
    withCredentials: false,
}

const firebase_identity = 'firebase_identity'

function axiosMiddleware(Vue, axios, store, router) {
    // add auth token
    axios.interceptors.request.use(config => {
            if (!config.headers[firebase_identity]) {
                const auth_token = store.getters['firebase_auth/firebase_identity']

                if (auth_token) {
                    if (!config.params || !config.params.noauth) {
                        config.headers[firebase_identity] = auth_token
                    }
                }
            }

            return {...axios_config, ...config}
        }, error => Promise.reject(error)
    )

    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response.status === 403 || error.response.status === 402) {
            store.dispatch('auth/logout')
            router.push({name: 'Create'})
        }
        return Promise.reject(error);
    });
}

export default axiosMiddleware
