<template>
    <label class="migration-payment-card">
        <div class="migration-payment-card-input">
            <input type="radio"
                   :value="value"
                   :checked="isChecked"
                   @change="handleCheckCard">
            <div class="checkmark"></div>
        </div>
        <div class="migration-payment-card-preview">
            <img :src="cardPreviewSrc" alt="card preview">
        </div>
        <div class="migration-payment-card-number">
            <span> •••• </span>
            <span class="d-none d-sm-inline"> •••• </span>
            <span class="d-none d-sm-inline"> •••• </span>
            <span> {{ card.CardLast4 }} </span>
        </div>
        <div class="migration-payment-card-date">
            <span>{{ cardExpMonth }} / {{ card.CardExpiryYear }}</span>
            <svg width="24" height="24" v-if="isCardExpired">
                <use xlink:href="#exclam-circle-icon"></use>
            </svg>
        </div>
    </label>
</template>

<script>
import {computed, toRefs} from "vue";

export default {
    name: 'MigrationPaymentCard',
    emits: ["update:modelValue"],
    props: {
        card: {
            type: Object,
            required: true
        },
        modelValue: {
            default: ''
        },
        value: {
            type: String, default: undefined
        }
    },
    setup(props, {emit}) {
        // Props
        const {card, value, modelValue} = toRefs(props);

        // Computed
        const isChecked = computed(() => {
            return modelValue.value === value.value;
        });

        const cardPreviewSrc = computed(() => {
            const cardBrand = card.value.CardType;
            return require(`@/assets/images/cards/${cardBrand}.svg`);
        })

        const cardExpMonth = computed(() => {
            return card.value.CardExpiryMonth.toString().padStart(2, '0');
        })

        const isCardExpired = computed(() => {
            const cardExpMonth = card.value.CardExpiryMonth;
            const cardExpYear = card.value.CardExpiryYear;

            let today, someday;

            today = new Date();
            someday = new Date();
            someday.setFullYear(cardExpYear, cardExpMonth, 1);

            return someday <= today;
        })

        // Methods
        function handleCheckCard(event) {
            emit('update:modelValue', event.target.value)
        }

        return {
            card,
            isChecked,
            cardPreviewSrc,
            cardExpMonth,
            isCardExpired,
            handleCheckCard,
        }
    }
}
</script>

<style lang="scss" scoped>
.migration-payment-card {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 0;
    border-bottom: 1px solid $theme-gray-color;

    @media (min-width: $sm) {
        padding: 16px 0;
    }

    &-input {
        margin-right: 8px;

        input {
            position: absolute;
            left: -9999999px;
        }

        .checkmark {
            position: relative;
            width: 24px;
            height: 24px;
            background: $theme-dark-light-color;
            box-shadow: 1px 1px 1px 0 rgba($theme-black-color, .15) inset;
            border-radius: 50%;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                transform-origin: center center;
                width: 16px;
                height: 16px;
                background: $theme-secondary-color;
                border-radius: 50%;
                transition: $transition-time-main;
            }
        }

        input:checked ~ .checkmark::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &-preview {
        max-width: 56px;
        margin-right: 8px;
        line-height: 1;

        @media (min-width: $sm) {
            margin-right: 24px;
        }

        img {
            max-width: 100%;
        }
    }

    &-number {
        span {
            font-size: .75rem;
            color: $white;

            @media (min-width: $sm) {
                font-size: 1rem;
            }
        }
    }

    &-date {
        display: flex;
        align-items: center;
        margin-left: auto;
        font-size: .75rem;

        @media (min-width: $sm) {
            font-size: 1rem;
        }

        svg {
            fill: $danger;
            margin-left: 8px;
        }
    }
}
</style>
