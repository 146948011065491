<template>
    <app-loader v-if="isLoading" full-block/>

    <app-simple-icon-button icon="chevron-left" @click="prevStep">
        Go back
    </app-simple-icon-button>

    <app-stepper-content-header :step-content-data="stepContentData"/>

    <div class="app-stepper-content-body">
        <profile-details v-if="profileDetailsData && billingDetailsData" :profile-details-data="profileDetailsData"
                         :billing-details-data="billingDetailsData"/>

        <mobile-service-list v-if="serviceList.length" :service-list="serviceList"/>

        <app-animation-button @click="nextStep"/>
    </div>
</template>

<script>
import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

import AppLoader from "@/components/app-loader/index.vue";
import AppStepperContentHeader from "@/components/app-stepper/app-stepper-content-header.vue";
import ProfileDetails from "@/components/app-migration-steps/migration-confirmation/profile-details.vue";
import MobileServiceList from "@/components/app-migration-steps/migration-confirmation/mobile-services/list.vue";
import AppAnimationButton from "@/components/app-animation-button/index.vue";
import AppSimpleIconButton from "@/components/app-simple-icon-button/index.vue";

export default {
    name: 'MigrationConfirmation',
    emits: ["nextStep", "prevStep"],
    props: {
        stepContentData: {
            type: Object,
            default() {
                return {
                    title: "Title",
                    subtitle: "Subtitle"
                }
            }
        }
    },
    components: {
        AppAnimationButton,
        AppLoader,
        MobileServiceList,
        ProfileDetails,
        AppStepperContentHeader,
        AppSimpleIconButton,
    },
    setup(props, {emit}) {
        // Props
        const {stepContentData} = props;

        // Store
        const store = useStore();
        const customerUUID = store.getters['auth/customer_uuid'];

        // Data
        const profileDetailsData = ref(null);
        const billingDetailsData = ref(null);
        const serviceList = ref([])
        const isLoading = ref(true);

        // Route
        const router = useRouter();

        // Methods
        function nextStep() {
            router.push({name: 'Done'});
            emit('nextStep');
        }

        function prevStep() {
            router.push({name: 'Payment'});
            emit('prevStep');
        }

        function getNextDOM(domday) {
            if (!domday) {
                return ''
            }
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth();
            const currentYear = currentDate.getFullYear();
            const currentDay = currentDate.getDate();
            let domMonth = currentMonth;
            let domYear = currentYear;
            if (currentDay >= domday) {
                domMonth = currentMonth + 1;
                if (domMonth > 11) {
                    domMonth = 0;
                    domYear += 1;
                }
            }
            const nextDOMDate = new Date(domYear, domMonth, domday);
            const dateString = new Date(nextDOMDate.getTime() - (currentDate.getTimezoneOffset() * 60000))
                .toISOString()
                .split("T")[0];
            return dateString
        }

        function getProfileDetails() {
            return store.dispatch('firebase_customer/getAccountCustomerByUUID', customerUUID)
                .then((response) => {
                    profileDetailsData.value = {
                        first_name: response.FirstName,
                        last_name: response.LastName,
                        email: response.Email,
                        phone: response.MobileNumber,
                        account_number: response.UUID.substring(0, 8),
                        account_renews_on: getNextDOM(response.BillingDOM),
                    }
                })
        }

        function getPlanList() {
            return store.dispatch('plan/getDetails')
                .then(({data}) => {
                    serviceList.value = data;
                })
        }

        function getBalance() {
            return store.dispatch('plan/getBalance')
                .then(({data}) => {
                    billingDetailsData.value = {
                        account_balance: data.balance,
                        account_renews_on: getNextDOM(data.billingdom),
                    }
                })
        }

        function getConfirmationPageData() {
            isLoading.value = true;

            const getDataMethods = [
                getPlanList(),
                getProfileDetails(),
                getBalance(),
            ]

            Promise.all(getDataMethods)
                .finally(() => {
                    isLoading.value = false;
                });
        }

        function changeStepContentData() {
            stepContentData.title = "Confirm your plan and details"
            stepContentData.subtitle = "Let's make sure everything is in order"
        }

        onMounted(() => {
            getConfirmationPageData();
            changeStepContentData();
        })

        return {
            isLoading,
            serviceList,
            stepContentData,
            profileDetailsData,
            billingDetailsData,
            nextStep,
            prevStep,
        }
    }
}
</script>

<style lang="scss" scoped>
:deep {
    .app-loader__mask {
        background: rgba($theme-darkness-light-color, 1);
        border-radius: 8px;
    }
}

.btn-simple-with-icon {
    margin-bottom: 40px;
}
</style>
