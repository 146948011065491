<template>
    <div class="app-hint" :class="'app-hint--' + type">
        <div class="app-hint__icon">
            <svg width="24" height="24">
                <use :xlink:href="`hint-${type}-icon`"></use>
            </svg>
        </div>
        <div class="app-hint__message">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppHint',
    props: {
        type: {
            type: String,
            default: 'warning'
        }
    }
}
</script>

<style lang="scss" scoped>
.app-hint {
    position: relative;
    display: flex;
    width: 100%;
    background: $white;
    color: $theme-dark-color;
    padding: 8px 16px;
    border-radius: 4px;

    &--warning {
        background: $theme-secondary-dark-color;
        color: $theme-secondary-color;
    }

    &__icon {
        width: 20px;
        height: 20px;
        flex: 1 0 20px;
        max-width: 20px;
        margin-right: 10px;
    }

    &__message {
        font-size: .75rem;
        font-weight: 400;

        @media (min-width: $sm) {
            font-size: .875rem;
        }

        :deep {
            p {
                margin: 0 0 10px;

                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
}
</style>
