import store from '@/store'

export default (to, from, next) => {
    if (to.query.token && (to.query.token !== store.getters['firebase_auth/customer_token'])) {
        store.dispatch('auth/logout');
        return next();
    }
    return {
        allow: !store.getters["auth/customer_uuid"],
        redirect: {name: 'Payment'},
    }
};
