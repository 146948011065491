<template>
    <label class="form-checkbox" :class="{'is-checked': model}">
        <input type="checkbox" v-model="model" :value="value">
        <span class="form-checkbox__checkmark">
            <img src="@theme/assets/form-checkbox-icon.svg">
        </span>
        <div class="form-checkbox__label">
            <slot></slot>
        </div>
    </label>
</template>

<script>
export default {
    name: 'FormCheckbox',
    props: {
        modelValue: {
            type: [Array, Boolean]
        },
        value: {
            type: [Boolean, Object]
        },
        label: {
            type: String
        }
    },
    computed: {
        model: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.form-checkbox {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    &.is-checked {

        .form-checkbox__checkmark {
            background: $theme-secondary-color;
        }
    }

    input {
        position: absolute;
        left: -9999999px;
        opacity: 0;
    }

    &__checkmark {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        flex: 1 0 24px;
        max-width: 24px;
        border-radius: 6px;
        background: $theme-dark-color;
        box-shadow: 0 0 1px 0 rgba($theme-black-color, .15) inset;
        margin-right: 8px;
        transition: $transition-time-main;
    }

    &__label {
        font-size: .75rem;
        color: $white;
        user-select: none;
        flex: 1 0 auto;
        max-width: 100%;

        @media (min-width: $sm) {
            font-size: .875rem;
        }

        :deep {

            a {
                @extend %simpleLink;
            }
        }
    }
}
</style>
