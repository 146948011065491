import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    firstname: {
        name: 'firstname',
        required: true,
        maxlength: 40,
		// format: /^(?=.{1,16}$)[a-zA-Z0-9]+((\ |\'|\-)?[a-z A-Z0-9])*$/,
        format: /^(?=.{1,40}$)[a-zA-ZāēīōūĀĒĪŌŪ ]+((\'|\-)?[a-zA-ZāēīōūĀĒĪŌŪ ])*$/,
        custom_message: `Sorry, that\'s not a valid First name`,
    },
    lastname: {
        name: 'lastname',
        required: true,
        maxlength: 40,
        // format: /^(?=.{1,16}$)[a-zA-Z0-9]+((\ |\'|\-)?[a-z A-Z0-9])*$/,
        format: /^(?=.{1,40}$)[a-zA-ZāēīōūĀĒĪŌŪ ]+((\'|\-)?[a-zA-ZāēīōūĀĒĪŌŪ ])*$/,
        custom_message: `Sorry, that\'s not a valid Last name`,
    },
    email: {
        name:'email',
        required: true,
        maxlength: 255,
        format: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
        custom_message: `Sorry, that\'s not a valid Email`,
    },
    pass: {
        name: 'pass',
        required: true,
    },
    passnew: {
        name: 'passnew',
        required: true,
        customPasswordCheck: true,
    },
})
