<template>
    <form @submit="submitCreateForm" class="migration-step-form">
        <div class="migration-step-form-body">
            <div class="row">
                <div class="col-sm-6">
                    <form-input v-model="formData.firstname"
                                type="text"
                                placeholder="First Name"
                                :error="inputErrors.fields.firstname"
                                icon="form-input-user"/>
                </div>
                <div class="col-sm-6">
                    <form-input v-model="formData.lastname"
                                type="text"
                                placeholder="Last Name"
                                :error="inputErrors.fields.lastname"
                                icon="form-input-user"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <form-input v-model="formData.email"
                                type="email"
                                placeholder="Email"
                                :error="inputErrors.fields.email"
                                icon="form-input-email"/>
                </div>
                <div class="col-sm-6">
                    <form-input v-model="formData.passnew"
                                type="password"
                                placeholder="Password"
                                :error="inputErrors.fields.passnew"
                                icon="form-input-lock"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <form-checkbox v-model="formDataAccept.isAcceptTerms"
                                   @change="changeAcceptCheckbox"
                    >
                        I accept the
                        <router-link to="#">Terms &amp; Conditions</router-link>
                    </form-checkbox>
                    <div v-if="!isAcceptTermsField" class="errormsg">* Please accept the Terms &amp; Conditions</div>
                    <form-checkbox v-model="formDataAccept.isAcceptPolicy"
                                   @change="changeAcceptCheckbox"
                    >
                        I accept the
                        <router-link to="#">Privacy Policy</router-link>
                    </form-checkbox>
                    <div v-if="!isAcceptPrivacyPolicyField" class="errormsg">* Please accept the Privacy Policy</div>
                </div>
                <div class="col-sm-6">
                    <form-checkbox v-model="formDataAccept.isAcceptedNewsletters"
                                   @change="changeAcceptCheckbox"
                    >
                        I want to receive newsletters from <br class="d-sm-none"> Rocket Mobile
                    </form-checkbox>
                    <form-checkbox v-model="formDataAccept.isAcceptedOffers"
                                   @change="changeAcceptCheckbox"
                    >
                        I want to receive special offers from <br class="d-sm-none"> Rocket Mobile
                    </form-checkbox>
                </div>
            </div>
        </div>
    </form>

    <p class="migration-step-form__change-type">
        If you already have an account, go to
        <button type="button" @click="switchFormType">Login</button>
    </p>

    <app-animation-button :disabled="!isAcceptTermsPolicy" @click="submitCreateForm"/>
</template>

<script>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";

import {toast} from "vue3-toastify";

import FormInput from "@/components/app-form-components/form-input.vue";
import FormCheckbox from "@/components/app-form-components/form-checkbox.vue";
import UserDetailsValidator from "@/validators/user-details-validator";
import AppAnimationButton from "@/components/app-animation-button/index.vue";

export default {
    name: 'MigrationCreateSignupForm',
    emits: ['submitForm', 'switchFormType', 'fillLoginPreData', 'changeAcceptCheckbox', 'startSubmitForm', 'doneSubmitForm'],
    components: {
        AppAnimationButton,
        FormInput,
        FormCheckbox,
    },
    props: {
        acceptData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    setup(props, {emit}) {
        // Props
        const {acceptData} = props;

        // Store
        const store = useStore();

        // Data
        const formData = ref({
            firstname: '',
            lastname: '',
            email: '',
            passnew: '',
        })

        const formDataAccept = ref({
            isAcceptTerms: false,
            isAcceptPolicy: false,
            isAcceptedNewsletters: false,
            isAcceptedOffers: false,
        })

        const inputErrors = ref({fields: {},});

        // Computed
        const isAcceptTermsPolicy = computed(() => {
            return formDataAccept.value.isAcceptTerms && formDataAccept.value.isAcceptPolicy
        })

        // Computed
        const isAcceptTermsField = computed(() => {
            return formDataAccept.value.isAcceptTerms
        })

        // Computed
        const isAcceptPrivacyPolicyField = computed(() => {
            return formDataAccept.value.isAcceptPolicy
        })

        const customerToken = computed(() => {
            return store.getters['firebase_auth/customer_token']
        })

        // Watch
        watch(acceptData, () => {
            fillAcceptData()
        })

        function fillAcceptData() {
            formDataAccept.value = acceptData;
        }

        // Methods
        function formValidation() {
            let isValid = true;

            inputErrors.value.fields = {};

            const values = {
                firstname: {
                    value: formData.value.firstname.trim().replace(/ {2,}/g, ' '),
                },
                lastname: {
                    value: formData.value.lastname.trim().replace(/ {2,}/g, ' '),
                },
                email: {
                    value: formData.value.email,
                },
                passnew: {
                    value: formData.value.passnew,
                },
            }

            for (const key in values) {
                if (UserDetailsValidator.isRuleExists(key)) {
                    if (UserDetailsValidator.isInvalid(key, values[key].value, values[key])) {
                        inputErrors.value.fields[key] = UserDetailsValidator.getError()

                        isValid = false
                    }
                }
            }

            return isValid
        }

        function changeAcceptCheckbox() {
            emit('changeAcceptCheckbox', formDataAccept.value)
        }

        function accessSubmitForm() {
            emit('doneSubmitForm');
            emit('submitForm');
        }

        function switchFormType() {
            emit('switchFormType', false)
        }

        function fillLoginPreData(data) {
            emit('fillLoginPreData', data)
        }

        function submitCreateForm() {
            if (formValidation()) {
                emit('startSubmitForm');

                const updateFirebaseUserPayload = {
                    'is-accepted-terms': true,
                    NotifySpecialOffers: formDataAccept.value.isAcceptedOffers ? 2 : 0,
                    NotifyNewsletters: formDataAccept.value.isAcceptedNewsletters ? 2 : 0
                }

                const updateAuthUserPayload = {
                    'Email': formData.value.email,
                    'FirstName': formData.value.firstname,
                    'LastName': formData.value.lastname,
                }

                store.dispatch('firebase_auth/FirebaseSignUp', formData.value)
                    .then(() => store.dispatch('firebase_accounts/updateUser', updateFirebaseUserPayload))
                    .then(() => store.dispatch('auth/attemptUser', customerToken.value))
                    .then((uuid) => store.dispatch('auth/updateUser', {'payload': updateAuthUserPayload, ...{'UUID': uuid}}))
                    .then(() => {
                        accessSubmitForm();
                    })
                    .catch((error) => {
                        emit('doneSubmitForm');
                        if (error.message) {
                            toast.error(error.message)
                        }
                        switchFormType();
                        fillLoginPreData(formData.value);
                    })
            }
        }

        function prefillUserData() {
            store.dispatch('auth/prefillUserData', customerToken.value)
                .then(({data}) => {
                    formData.value.email = data.email;
                    formData.value.firstname = data.firstname;
                    formData.value.lastname = data.lastname;
                })
        }

        // Mounted
        onMounted(() => {
            prefillUserData();
            fillAcceptData();
        })

        return {
            formData,
            formDataAccept,
            inputErrors,
            isAcceptTermsPolicy,
            isAcceptTermsField,
            isAcceptPrivacyPolicyField,
            acceptData,
            changeAcceptCheckbox,
            submitCreateForm,
            accessSubmitForm,
            switchFormType,
        }
    }
}
</script>

<style lang="scss" scoped>
    .errormsg {
        margin-top: -10px;
        margin-left: 30px;
        margin-bottom: 5px;
        font-size: 0.75rem;
        color: #FE4C6C;
    }
</style>
