<template>
    <form class="migration-step-form">
        <div class="migration-step-form-body">
            <div class="row">
                <div class="col-lg-6" v-if="stripeElements">
                    <form-input-stripe ref="app-input-stripe"
                                       type="text"
                                       icon="form-input-card"
                                       stripe-element-type="cardNumber"
                                       :stripe-elements="stripeElements"
                                       @mounted="handleMount"
                    />
                    <div class="row row-payment">
                        <div class="col-6 col-6-payment">
                            <form-input-stripe ref="app-input-stripe"
                                               type="text"
                                               icon="form-input-card-expire"
                                               stripe-element-type="cardExpiry"
                                               :stripe-elements="stripeElements"
                                               @mounted="handleMount"
                            />
                        </div>
                        <div class="col-6 col-6-payment">
                            <form-input-stripe ref="app-input-stripe"
                                               icon="form-input-card-cvv"
                                               stripe-element-type="cardCvc"
                                               :stripe-elements="stripeElements"
                                               @mounted="handleMount"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <app-hint>
                        <p>
                            The first charge to your card will only be made when your plan renews.
                        </p>
                    </app-hint>
                </div>
            </div>
        </div>
    </form>

    <app-animation-button @click="successAddPaymentMethod"/>
</template>

<script>
import {ref, toRefs} from "vue";

import AppHint from "@/components/app-hint/index.vue";
import FormInputStripe from "@/components/app-form-components/form-input-stripe.vue";
import AppAnimationButton from "@/components/app-animation-button/index.vue";

export default {
    name: 'MigrationPaymentForm',
    emits: ['successAddPaymentMethod', 'mounted'],
    props: {
        stripeElements: {
            type: Object,
            required: true
        }
    },
    components: {
        AppAnimationButton,
        AppHint,
        FormInputStripe,
    },
    setup(props, {emit}) {
        // Props
        const {stripeElements} = toRefs(props);

        // Data
        const stripe_inputs = ref([
            {stripeElementType: 'cardNumber', label: 'Card number'},
            {stripeElementType: 'cardExpiry', label: 'Card expiry'},
            {stripeElementType: 'cardCvc', label: 'CVC'},
        ])

        function handleMount(stripe_element) {
            const stripe_input = getStripeInput(stripe_element)

            if (stripe_input) {
                stripe_input.element = stripe_element
            }

            if (Object.values(stripe_inputs.value).every(({element}) => element)) {
                stripe_inputs.value = [...stripe_inputs.value]
                emit('mounted', stripe_inputs.value)
            }
        }

        function getStripeInputIndex(stripe_element) {
            const stripe_element_type = stripe_element._componentName
            return stripe_inputs.value.findIndex(({stripeElementType}) => stripeElementType == stripe_element_type)
        }

        function getStripeInput(stripe_element) {
            return stripe_inputs.value[getStripeInputIndex(stripe_element)]
        }

        // Methods
        function successAddPaymentMethod() {
            emit('successAddPaymentMethod')
        }

        return {
            stripeElements,
            successAddPaymentMethod,
            handleMount,
        }
    }
}
</script>

<style lang="scss" scoped>

.migration-step-form {
    :deep {
        .app-hint {
            margin-bottom: 20px;

            @media (min-width: $sm) {
                margin-bottom: 30px;
            }
        }

        .row-payment {

            @media (max-width: $sm) {
                margin: 0 -7px;
            }
        }

        .col-6-payment {
            @media (max-width: $sm) {
                padding: 0 7px;
            }
        }
    }
}
</style>
