<template>
    <div class="migration-done-loader">
        <app-loader/>
        <p class="migration-done-loader__text">
            Thanks! Your mobile service(s) will seamlessly switch over to Rocket Mobile in a few moments. <span>Please turn
            on airplane mode for 3-5 seconds and then switch airplane mode back off to ensure your connection
            re-connects with the new system.</span>
        </p>
    </div>
</template>

<script>
import AppLoader from "@/components/app-loader/index.vue";

export default {
    name: 'MigrationDoneLoader',
    components: {
        AppLoader,
    }
}
</script>

<style lang="scss" scoped>
.migration-done-loader {
    text-align: center;

    &__text {
        font-size: .875rem;
        font-weight: 400;
        color: $white;
        margin-top: 40px;

        span {
            font-weight: 600;
            color: $theme-primary-color;
        }
    }
}
</style>
