<template>
    <form @submit.prevent="handleSubmitForm">
        <div class="row">
            <div class="col-12">
                <h2>
                    Login form
                </h2>
            </div>
            <div class="col-sm-6">
                <form-input v-model="formData.AuthID"
                            type="email"
                            placeholder="Email"
                            icon="form-input-email"/>
            </div>
            <div class="col-sm-6">
                <form-input v-model="formData.AuthCode"
                            type="password"
                            placeholder="Password"
                            icon="form-input-lock"/>
            </div>
            <div class="col-12 mt-5">
                <app-animation-button/>
            </div>
        </div>
    </form>
</template>

<script>
import {ref} from "vue";

import FormInput from "@/components/app-form-components/form-input.vue";
import AppAnimationButton from "@/components/app-animation-button/index.vue";
import {useStore} from "vuex";
import {toast} from "vue3-toastify";

export default {
    name: 'AppGenerateTokenLoginForm',
    emits: ['onLogin'],
    components: {
        FormInput,
        AppAnimationButton
    },
    setup(_, {emit}) {
        // Data
        const formData = ref({
            AuthID: '',
            AuthCode: ''
        })

        // Store
        const store = useStore();

        // Methods
        function handleSubmitForm() {
            store.dispatch('auth/authCheck', formData.value)
                .then(({data}) => {
                    emit('onLogin', data)
                })
                .catch(error => {
                    if (error.message) {
                        toast.error(error.message)
                    }
                })
        }

        return {
            formData,
            handleSubmitForm
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
