import {db} from '@/utils/firebase.js';

const DOC_USERS = '/users';

export default {
    namespaced: true,
    state: {},

    getters: {
        // collection_users_name: (state) => COLLECTION_ACCOUNTS,
        doc_users_name: (state) => DOC_USERS,
    },

    actions: {
        CreateAccount({}, {user_data, uid}) {
            if (uid) {
                return db
                    .doc(`${DOC_USERS}/${uid}`)
                    .set(user_data)
                    .then(() => {
                        console.info('Document successfully written!');
                        return Promise.resolve();
                    })
                    .catch((error) => {
                        console.error('Error writing document: ', error);
                        return Promise.reject(error);
                    });
            } else {
                return Promise.reject('No UUID');
            }
        },

        updateUser({rootGetters, getters, commit}, data) {
            const user_uuid = rootGetters['firebase_auth/auth_user']?.uid

            const user = {
                ...data,
            }

            return db
                .doc(`${DOC_USERS}/${user_uuid}`)
                .update(user)
                .then(() => {
                    return Promise.resolve(user)
                })
                .catch(error => {
                    console.log('updateUser error', error)
                    return Promise.reject(error)
                })
        },

        DeleteAccount({}, uid) {
            if (uid) {
                return db
                    .doc(`${DOC_USERS}/${uid}`)
                    .delete()
            }
        },

        GetAccountByUid({}, uid) {
            if (!uid) {
                return Promise.reject('no user ID');
            } else {
                return db
                    .doc(`${DOC_USERS}/${uid}`)
                    .get()
                    .then((doc) => {
                        if (doc.exists) {
                            return Promise.resolve(doc.data());
                        } else {
                            console.info('No such document!');

                            return Promise.resolve(null);
                        }
                    })
                    .catch((error) => {
                        console.error('Error getting document:', error);
                        return Promise.reject(error);
                    });
            }
        },
    },
    mutations: {},
};
