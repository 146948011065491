<template>
    <button type="button" class="btn-simple-with-icon" @click="handleClick">
        <svg width="24" height="24">
            <use :xlink:href="`#${icon}`"></use>
        </svg>
        <slot />
    </button>
</template>

<script>
import {toRefs} from "vue";

export default {
    name: 'AppSimpleIconButton',
    emits: ['click'],
    props: {
        icon: {
            type: String,
            default: 'chevron-left'
        }
    },
    setup(props, {emit}) {
        const {icon} = toRefs(props)

        // Methods
        function handleClick() {
            emit('click')
        }

        return {
            icon,
            handleClick
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-simple-with-icon {
    display: flex;
    align-items: center;
    color: $theme-primary-color;
    padding: 0;
    font-size: 1rem;
    font-weight: 600;
    transition: $transition-time-main;

    &:hover {
        color: $theme-secondary-color;

        svg {
            fill: $theme-secondary-color;
        }
    }

    svg {
        margin-right: 8px;
        fill: $theme-primary-color;
        transition: $transition-time-main;
    }
}
</style>
