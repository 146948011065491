<template>
    <app-loader v-if="isLoading" full-block/>

    <app-simple-icon-button v-if="isShowGoBack" icon="chevron-left" @click="switchPaymentType(false)">
        Go back
    </app-simple-icon-button>

    <app-stepper-content-header :step-content-data="stepContentData"/>

    <div class="app-stepper-content-body">
        <migration-payment-form v-if="isShowForm"
                                :stripe-elements="stripeElements"
                                @successAddPaymentMethod="addPaymentMethod"
                                @mounted="formMount"
        />

        <migration-payment-cards v-else
                                 :cards="paymentMethods"
                                 @successAddPaymentMethod="nextStep"
                                 @addNewCard="switchPaymentType"
        />
    </div>
</template>

<script>
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";

import AppLoader from "@/components/app-loader/index.vue";
import AppStepperContentHeader from "@/components/app-stepper/app-stepper-content-header.vue";
import MigrationPaymentForm from "@/components/app-migration-steps/migration-payment/form/index.vue";
import MigrationPaymentCards from "@/components/app-migration-steps/migration-payment/cards/list.vue";
import AppSimpleIconButton from "@/components/app-simple-icon-button/index.vue";
import {toast} from "vue3-toastify";
import {useRouter} from "vue-router";

export default {
    name: 'MigrationPayment',
    emits: ["nextStep"],
    props: {
        stepContentData: {
            type: Object,
            default() {
                return {
                    title: "Title",
                    subtitle: "Subtitle"
                }
            }
        }
    },
    components: {
        AppLoader,
        AppSimpleIconButton,
        MigrationPaymentCards,
        MigrationPaymentForm,
        AppStepperContentHeader,
    },
    setup(props, {emit}) {
        // Store
        const store = useStore();
        const customerUUID = store.getters['auth/customer_uuid'];

        // Props
        const {stepContentData} = props;

        // Data
        const stripeElements = ref(null);
        const paymentMethods = ref([]);
        const isShowForm = ref(true);
        const isLoading = ref(true);
        const stripe = ref(null);
        const stripe_inputs = ref([]);

        // Route
        const router = useRouter();

        // Computed
        const isShowGoBack = computed(() => {
            return isShowForm.value && (paymentMethods.value.length > 0)
        })

        // Methods
        function nextStep() {
            router.push({name: 'Confirm'});
            emit('nextStep');
        }

        function switchPaymentType(isForm) {
            stepContentData.title = "Add Payment Method";
            stepContentData.subtitle = "Please provide your credit or debit card details below.";

            if (!isForm) {
                stepContentData.title = "Add Payment Method";
                stepContentData.subtitle = "Please select one of your credit cards.";
            }

            isShowForm.value = isForm
        }

        function initStripe() {
            store.dispatch('stripeInit').then(stripeLib => {
                stripe.value = stripeLib
                stripeElements.value = stripeLib.elements();
            })
        }

        function getCustomerPaymentMethods() {
            isLoading.value = true;

            store.dispatch('firebase_customer/getCustomerPaymentMethodsByUUID', customerUUID)
                .then((customerPaymentMethods) => {
                    isShowForm.value = customerPaymentMethods.length === 0;
                    paymentMethods.value = customerPaymentMethods;
                })
                .finally(() => {
                    isLoading.value = false;
                    switchPaymentType(paymentMethods.value.length === 0);
                });
        }

        function formMount(form_stripe_inputs) {
            stripe_inputs.value = form_stripe_inputs;
        }

        function addPaymentMethod() {
            isLoading.value = true;

            store.dispatch('payment_methods/setupIntentClientSecret').then(SETUP_INTENT_CLIENT_SECRET => {
                return stripe.value.confirmCardSetup(SETUP_INTENT_CLIENT_SECRET, {
                    payment_method: {
                        card: stripe_inputs.value[0].element,
                    },
                })
                    .then(response => {
                        isLoading.value = false;

                        if (response.error) {
                            return Promise.reject(response.error)
                        }

                        nextStep();

                    }).catch(error => {
                        isLoading.value = false;
                        Promise.reject(error);
                    }).finally(() => isLoading.value = false)
            }).catch((error) => {
                isLoading.value = false;
                toast.error(error.message)
            }).finally(() => isLoading.value = false)
        }

        // Mounted
        onMounted(() => {
            initStripe();
            getCustomerPaymentMethods();
        })

        return {
            stepContentData,
            stripeElements,
            paymentMethods,
            isShowForm,
            isShowGoBack,
            isLoading,
            nextStep,
            switchPaymentType,
            formMount,
            addPaymentMethod,
        }
    }
}
</script>

<style lang="scss" scoped>
:deep {
    .app-loader__mask {
        background: rgba($theme-darkness-light-color, 1);
        border-radius: 8px;
    }
}

.btn-simple-with-icon {
    margin-bottom: 40px;
}
</style>
